import { useEffect, useState } from "react";
import "./style.css";
import Bot from "../../Components/Bot";
export default function Order() {
  const [orders, setOrder] = useState();
  function getOrder() {
    let formData = new FormData();
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    formData.append("id", myParam);
    fetch("https://comeback-team.ru/getLink.php", {
      body: formData,
      method: "post",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setOrder(data[0]);
      });
  }

  useEffect(() => {
    getOrder();
  }, []);
  return (
    <div>
      <div className="ordeerTitle">
        {orders &&
          orders.StatusComp == "Создан" &&
          " Спасибо за заказ! В ближайшее время с вами свяжется менеджер для подтверждения"}
        <p></p>
      </div>
      {orders && (
        <div className="orderBody">
          <ul>
            <div className="orderId">Заказ № {orders.ID}</div>
            <div className="orderEm statusOrder">
              Статус заказа:{" "}
              <b style={{ textTransform: "uppercase" }}>{orders.StatusComp}</b>
            </div>
            {JSON.parse(orders.details).map((em) => {
              return (
                <li className="orderEm">
                  {em[em.length - 1] == ":" ? em.replace(":", ": -") : em}
                </li>
              );
            })}
            <div className="orderEm linkOrder">
              Ссылка для отслеживания статуса заказа: <br />
              {window.location.href}
            </div>
            <div className="orderEm linkOrder">
              Связаться с менеджером: <br />
              <a href="https://t.me/rarestore_manager">
                t.me/rarestore_manager
              </a>
            </div>
          </ul>
          <img
            src="https://rarestore.ru/static/media/1.4b24fbcef28c7929ee0a.jpg"
            alt=""
          />
        </div>
      )}
      <Bot></Bot>
    </div>
  );
}
