import "./style.css";
import avatar from "./avatar.png";
import tgs from "./telegram.png";
import { useContext, useEffect, useRef, useState } from "react";
import "./style.css";
import Card from "../../Components/Card/Card";
import Slider from "../../Components/Slider";
import { routes } from "../../routes";
import { useNavigate, useParams } from "react-router-dom";
import CardBody from "../../Components/CardBody/CardBody";
import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import cc from "./cc.png";
import AnimatedPage from "../../AnimatedPage";
import Tg from "../../Components/Tg";
import { Zoom } from "react-reveal";
import Bot from "../../Components/Bot";
import InputRange from "react-input-range";
import filterIcon from "../Brand/filter.png";
import { StickyContainer, Sticky } from "react-sticky";
import close from "../../Components/CardBody/close.png";
export default function TelegramPage({ setCorzine, choosenBrand }) {
  const [cards, setCards] = useState([]);
  const [info, setInfo] = useState();
  const [card, setCard] = useState("");
  const [filteredCards, setFilteredCards] = useState([]);
  const [infoCard, setInfoCard] = useState(false);
  const [brandSearch, setBrandSearch] = useState("");

  //HEIGHT
  const firstM = useRef(null);

  const { myParam } = useParams();
  const [setter, isSetter] = useState(myParam);
  const [arrSizeList, setArrSizeList] = useState([]);
  const [numCards, setNumCards] = useState(12);
  const [scrolled, isScrolled] = useState(false);
  const [filterPop, isFilterPop] = useState(false);
  const Navigate = useNavigate();
  useEffect(() => {
    setNumCards(12);
  }, [setter]);
  let listener = null;

  useEffect(() => {
    listener = document.addEventListener("scroll", (e) => {
      if (document.body.clientWidth > 640) {
        let scrolled = document.scrollingElement.scrollTop;
        if (
          firstM &&
          firstM.current &&
          firstM.current.clientHeight != null &&
          elementRef &&
          elementRef.current &&
          elementRef.current.clientHeight != null
        ) {
          if (
            scrolled > firstM.current.clientHeight &&
            scrolled < elementRef.current.clientHeight - 140
          ) {
            isScrolled(true);
          }
          if (scrolled < firstM.current.clientHeight) {
            isScrolled(false);
          }
          if (scrolled > elementRef.current.clientHeight - 140) {
            isScrolled(false);
          }
        }
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, []);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(50000);
  const [arrShoes, setArrShoes] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("Все");
  const [arrSizes, setArrSizes] = useState([]);
  let cur = 0;
  const elementRef = useRef(null);

  const getCards = () => {
    let formData = new FormData();

    fetch("https://comeback-team.ru/cardsApi/getAll.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCards(data);
      });
  };

  useEffect(() => {
    if (cards.length > 0) {
      let arr = [];
      let allSizeArr = [];
      arr.push("Все");
      for (let i = 0; i < cards.length; i++) {
        arr.push(cards[i].Description);
        if (cards[i].Editions && cards[i].Editions.length > 0) {
          let arrs = cards[i].Editions.substr(1, cards[i].Editions.length - 2)
            .trim()
            .split(",");

          allSizeArr = allSizeArr.concat(arrs);
        }
      }
      allSizeArr = allSizeArr.map((em) => {
        return em.trim();
      });
      allSizeArr = new Set(allSizeArr);
      allSizeArr = Array.from(allSizeArr);
      setArrSizeList(allSizeArr);
      const uniqueNames = new Set(arr); // создаём коллекцию уникальных значений
      setArrShoes(Array.from(uniqueNames));
    }
  }, [cards]);
  // useEffect(() => {
  //   let allSizeArr = [];

  //   for (let i = 0; i < filteredCards.length; i++) {
  //     if (filteredCards[i].Editions && filteredCards[i].Editions.length > 0) {
  //       let arrs = filteredCards[i].Editions.substr(
  //         1,
  //         filteredCards[i].Editions.length - 2
  //       )
  //         .trim()
  //         .split(",");

  //       allSizeArr = allSizeArr.concat(arrs);
  //     }
  //   }

  //   allSizeArr = new Set(allSizeArr);
  //   allSizeArr = Array.from(allSizeArr);
  //   setArrSizeList(allSizeArr);
  // }, [currentFilter]);
  useEffect(() => {
    setBrandSearch("");
    setMin(0);
    setMax(50000);
    setCurrentFilter("Все");
    setNumCards(12);
    document.getElementsByName("sizes").forEach((em) => {
      em.checked = false;
    });
  }, [myParam]);
  function arrSize() {
    let arr = document.getElementsByName("sizes");
    let checkedArr = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].checked == true) {
        checkedArr.push(arr[i].value.trim());
      }
    }
    setArrSizes(checkedArr);
  }

  useEffect(() => {
    setFilteredCards(cards);
  }, [cards]);

  function setFilters() {
    let newArr = cards;
    if (arrSizes.length > 0) {
      newArr = newArr.filter((em) => {
        if (em.Editions && em.Editions.length > 0) {
          let arr = em.Editions.substr(1, em.Editions.length - 2)
            .trim()
            .split(",");
          for (let i = 0; i < arrSizes.length; i++) {
            for (let j = 0; j < arr.length; j++) {
              if (arrSizes[i].trim() == arr[j].trim()) {
                return em;
              }
            }
          }
        }
      });
    }

    if (currentFilter != "Все") {
      newArr = newArr.filter((em) => {
        if (em.Description == currentFilter) {
          return em;
        }
      });
    }
    if (brandSearch.length > 0) {
      newArr = newArr.filter((em) => {
        if (em.Title.toLowerCase().includes(brandSearch.toLowerCase())) {
          return em;
        }
      });
    }
    newArr = newArr.filter((em) => {
      if (Number(em.Price) >= min && Number(em.Price) <= max) {
        return em;
      }
    });
    setFilteredCards(newArr);
  }

  useEffect(() => {
    setFilters();
  }, [brandSearch, arrSizes, currentFilter, min, max]);

  const getInfo = () => {
    let formData = new FormData();
    formData.append("cat", myParam);
    fetch("https://comeback-team.ru/cardsApi/getBrandInfo.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setInfo(data[0]);
        if (data[0]) {
          setCard(data[0].images);
        }
      });
  };

  useEffect(() => {
    getCards();
  }, []);
  useEffect(() => {
    getCards();
    getInfo();
  }, [choosenBrand]);

  return (
    <div className="brandTg tgPage">
      <div ref={firstM}>
        <div className="tgAdds">
          <div className="photoUrl">
            <img src={avatar} alt="" />
            <div className="contactss">
              <p>{localStorage.tgName}</p>
              <div>Скидка 5%</div>
            </div>
          </div>
          <a
            href="https://t.me/rarestoreru"
            target="_blank"
            style={{ textDecoration: "none", color: "black" }}
            className="tgProfile"
          >
            <img src={tgs} alt="" />
            <div>
              <p>@RARESTORE</p>
              <span>Наш канал</span>
            </div>
          </a>
        </div>
      </div>
      {infoCard && (
        <CardBody setCorzine={setCorzine} closeCard={setInfoCard}></CardBody>
      )}{" "}
      <div className="brandBody" ref={elementRef}>
        {document.body.clientWidth < 640 && (
          <div
            className={`getStick`}
            style={!filterPop ? { height: "34vw" } : { height: "auto" }}
          >
            <div className="searchItems">
              <div
                className="iii"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={() => isFilterPop(!filterPop)}
              >
                <p>Показать фильтры</p>
                <img
                  className="crestFilter"
                  src={filterPop ? close : cc}
                  alt=""
                />
              </div>
              <div style={{ padding: "0 0.5vw" }}>
                <input
                  type="text"
                  placeholder="Поиск по названию товара"
                  value={brandSearch}
                  onChange={(e) => setBrandSearch(e.target.value)}
                  className="searchBrand"
                />
                <p className="catBrandBody">Категория:</p>
                <div className="brandScrollBody">
                  <div className="brandScroll">
                    {arrShoes &&
                      arrShoes.length > 0 &&
                      arrShoes.map((em, index) => {
                        return (
                          <div
                            className="choseBrand"
                            onClick={() => {
                              setCurrentFilter(em);
                            }}
                            style={
                              currentFilter == em ? { fontWeight: "700" } : {}
                            }
                            key={index}
                          >
                            {em}
                          </div>
                        );
                      })}
                  </div>
                </div>
                <p className="catBrandBody">Цена:</p>
                <div className="pricesBrand">
                  <div className="priceV">
                    <input
                      type="number"
                      placeholder="Руб."
                      onChange={(e) => {
                        setMin(e.target.value);
                      }}
                      value={min}
                    />
                  </div>{" "}
                  <p>―――――</p>
                  <div className="priceV">
                    <input
                      type="number"
                      placeholder="Руб."
                      onChange={(e) => setMax(e.target.value)}
                      value={max}
                    />
                  </div>
                </div>
                <p className="catBrandBody">Размеры:</p>
                <div className="greatSizes">
                  <div className="sizeColumn">
                    <p>Обувь</p>
                    {arrSizeList.length > 0 &&
                      arrSizeList.map((em, index) => {
                        if (em != "" && em.includes("EUR")) {
                          return (
                            <div key={index}>
                              <input
                                type="checkbox"
                                onClick={() => arrSize()}
                                name="sizes"
                                id=""
                                value={em}
                              />
                              <p>{em}</p>
                            </div>
                          );
                        }
                      })}
                  </div>
                  <div className="sizeColumn">
                    <p>Одежда</p>
                    {arrSizeList.length > 0 &&
                      arrSizeList.map((em, index) => {
                        if (em != "" && !em.includes("EUR")) {
                          return (
                            <div key={index}>
                              <input
                                type="checkbox"
                                onClick={() => arrSize()}
                                name="sizes"
                                id=""
                                value={em}
                              />
                              <p>{em}</p>
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div>
          <div className="cardItems">
            {filteredCards &&
              filteredCards.length > 0 &&
              filteredCards.map((em, index) => {
                // if (
                //   em.Title.toLowerCase().includes(brandSearch.toLowerCase()) &&
                //   Number(em.Price) >= Number(min) &&
                //   Number(em.Price) <= Number(max) &&
                //   (em.Description == currentFilter || currentFilter == "Все")
                // ) {
                if (cur < numCards) {
                  cur += 1;
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        localStorage.setItem("tovar", JSON.stringify(em));
                        setInfoCard(true);
                      }}
                    >
                      <Zoom>
                        <Card card={em} />
                      </Zoom>
                    </div>
                  );
                }
              })}
          </div>
          {filteredCards.length > 12 && numCards < filteredCards.length && (
            <div
              className="setCount"
              style={numCards > cards.length ? { display: "none" } : {}}
              onClick={() => setNumCards(numCards + 12)}
            >
              Показать еще
            </div>
          )}
          {filteredCards.length == 0 && (
            <div className="noSearch">
              По заданным фильтрам найдено 0 товаров
            </div>
          )}
        </div>
      </div>{" "}
      <div className="brandInfo">
        <div className="brandInfoImg">
          {info &&
            info.imagesDown &&
            info.imagesDown.split(" ").map((em, index) => {
              return (
                <div
                  key={index}
                  style={{ backgroundImage: "url(" + em + ")" }}
                ></div>
              );
            })}
        </div>
        <h4>{info && info.info}</h4>
      </div>
    </div>
  );
}
