import { useEffect, useState } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
export default function Brand() {
  const [brand, setBrandParam] = useState();

  const Navigate = useNavigate();
  const getBrand = () => {
    fetch("https://comeback-team.ru/cardsApi/getBrand.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setBrandParam(data);
      });
  };
  useEffect(() => {
    getBrand();
  }, []);
  return (
    <div className="brands">
      {brand &&
        brand.length > 0 &&
        brand.map((em, index) => {
          if (em.brand) {
            return (
              <div
                className="brands_em"
                key={index}
                onClick={() => Navigate("/brand/" + em.brand)}
              >
                {em.brand}
              </div>
            );
          }
        })}
    </div>
  );
}
