import "./style.css";
import iphone from "./1.jpeg";
import { Link } from "react-router-dom";
import tg from "./2.png";
export default function Bot() {
  return (
    <div className="bot">
      <div className="bot_content">
        <h2>В приложении дешевле</h2>

        <p>
          ◾️ Персональные акции, история заказов и многое другое в нашем
          телеграм приложении!📲
        </p>
        <p>
          ◾️ Отслеживание доставки. Уведомляем о прибытии и получении через
          бота, отвечаем на любые вопросы.🕘
        </p>
        <p>
          ◾️ Приложение всегда под рукой, достаточно просто открыть бота 🤖
        </p>

        {document.body.clientWidth < 640 && <img src={iphone} alt="" />}
        <p>
          Подписывайтесь, чтобы быть в курсе актуальных новостей, поступлений
          товаров, и получить <br /> ❗️<b>скидку 5%</b>❗️ на первый заказ
          через приложение.
        </p>
        <a href="https://t.me/rare_salingStore_bot" target="_blank">
          <div className="subscribe">
            <span>В приложение</span> <img src={tg} alt="" />
          </div>
        </a>
      </div>
      {document.body.clientWidth > 640 && <img src={iphone} alt="" />}
    </div>
  );
}
