import { Link } from "react-router-dom";
import "./style.css";
import { routes } from "../../routes";
import Tg from "../Tg";
import bank from "./banks.png";
export default function Footer() {
  return (
    <div className="footer">
      <div className="alala">
        <img
          src="https://brandshop.ru/assets/images/footer-icons/mir.svg"
          alt=""
        />
        <img
          src="https://brandshop.ru/assets/images/footer-icons/visa.svg"
          alt=""
        />
        <img
          src="https://brandshop.ru/assets/images/footer-icons/mastercard.svg"
          alt=""
        />
        <img
          src="https://brandshop.ru/assets/images/footer-icons/tinkoff.svg"
          alt=""
        />
        <img
          src="https://brandshop.ru/assets/images/footer-icons/sberpay.svg"
          alt=""
        />
        <img
          src="https://brandshop.ru/assets/images/footer-icons/split.svg"
          alt=""
        />
        <img
          src="https://brandshop.ru/assets/images/footer-icons/russian-post.svg"
          alt=""
        />
        <img
          src="https://brandshop.ru/assets/images/footer-icons/cdek.svg"
          alt=""
        />
      </div>
      <div className="footerBody">
        <div className="send">
          <h4>СВЯЖИТЕСЬ С НАМИ</h4>
          <p style={{ textAlign: "left" }}>
            Москва, ул. Шухова 18 <br />С 12:00 до 20:00
          </p>
          <a href="tel:84382425892">8 438 2425892</a>
        </div>
        <div className="about">
          <h4>О МАГАЗИНЕ</h4>
          <Link target="_blank" to={routes.about}>
            О нас
          </Link>
          <a href="#contacts">Контакты</a>
        </div>
        <div className="help">
          <h4>ПОМОЩЬ КЛИЕНТАМ</h4>
          <Link target="_blank" to={routes.dostavka}>
            Доставка
          </Link>
          {/*  <Link target="_blank" to={routes.oplata}>
            Оплата
          </Link> */}
          <Link target="_blank" to={routes.public}>
            Публичная оферта
          </Link>
          <Link target="_blank" to={routes.public}>
            Пользовательское соглашение
          </Link>
          <Link target="_blank" to={routes.public}>
            Политика конфиденциальности
          </Link>
        </div>
        <div className="subscribe">
          <div>
            <svg
              className="t-sociallinks__svg"
              width="25px"
              height="25px"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M66.9644 10H33.04C20.3227 10 10 20.3227 10 32.9573V67.1167C10 79.6816 20.3227 90 33.04 90H66.96C79.6816 90 90 79.6816 90 67.0384V32.9616C90.0044 20.3227 79.6816 10 66.9644 10ZM29.2 50.0022C29.2 38.5083 38.5257 29.2 50.0022 29.2C61.4786 29.2 70.8 38.5083 70.8 50.0022C70.8 61.4961 61.4743 70.8 50.0022 70.8C38.5257 70.8 29.2 61.4961 29.2 50.0022ZM72.8854 31.2027C70.2079 31.2027 68.0789 29.0824 68.0789 26.4049C68.0789 23.7273 70.2035 21.6027 72.8854 21.6027C75.5586 21.6027 77.6833 23.7273 77.6833 26.4049C77.6833 29.0824 75.5586 31.2027 72.8854 31.2027Z"
                fill="#ffffff"
              ></path>
              <path
                d="M50.0022 36.4011C42.4659 36.4011 36.4011 42.4876 36.4011 50.0022C36.4011 57.5124 42.4659 63.6033 50.0022 63.6033C57.5429 63.6033 63.6033 57.5124 63.6033 50.0022C63.6033 42.492 57.4514 36.4011 50.0022 36.4011Z"
                fill="#ffffff"
              ></path>
            </svg>
            <svg
              className="t-sociallinks__svg"
              width="25px"
              height="25px"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M83.1797 17.5886C83.1797 17.5886 90.5802 14.7028 89.9635 21.711C89.758 24.5968 87.9079 34.6968 86.4688 45.6214L81.5351 77.9827C81.5351 77.9827 81.124 82.7235 77.4237 83.548C73.7233 84.3724 68.173 80.6623 67.145 79.8378C66.3227 79.2195 51.7273 69.9438 46.5878 65.4092C45.1488 64.1724 43.5042 61.6989 46.7934 58.8132L68.3785 38.201C70.8454 35.7274 73.3122 29.956 63.0336 36.9642L34.2535 56.5459C34.2535 56.5459 30.9644 58.6071 24.7973 56.752L11.4351 52.6295C11.4351 52.6295 6.50135 49.5377 14.9298 46.4457C35.4871 36.7579 60.7724 26.864 83.1797 17.5886Z"
                fill="#ffffff"
              ></path>
            </svg>
            <a href="mailto:order@rarestore.ru">order@rarestore.ru</a>
          </div>
        </div>
      </div>
    </div>
  );
}
