import { useContext, useEffect, useState } from "react";
import "./style.css";
import Card from "../../Components/Card/Card";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import CardBody from "../../Components/CardBody/CardBody";
import AnimatedPage from "../../AnimatedPage";

export default function Sale({ setCorzine }) {
  const [cards, setCards] = useState();
  const Navigate = useNavigate();
  const [infoCard, setInfoCard] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("key");
  const getCards = () => {
    fetch("https://comeback-team.ru/cardsApi/getSales.php", {})
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCards(data);
      });
  };
  useEffect(() => {
    getCards();
  }, []);

  return (
    <div>
      <div
        className="preview"
        style={{
          backgroundImage:
            "url(https://static.tildacdn.com/tild3734-6664-4862-a137-383662396630/noroot.png)",
        }}
      >
        <div>
          <p>SALE %</p>
        </div>
      </div>
      <div className="strel">
        <span>Главная → Одежда →</span> Распродажа
      </div>
      {infoCard && (
        <CardBody closeCard={setInfoCard} setCorzine={setCorzine}></CardBody>
      )}
      <div className="cardItems">
        {cards &&
          cards.length > 0 &&
          cards.map((em, index) => {
            return (
              <div
                onClick={() => {
                  localStorage.setItem("tovar", JSON.stringify(em));
                  setInfoCard(true);
                }}
              >
                <Card key={index} card={em} />
              </div>
            );
          })}
      </div>
    </div>
  );
}
