import "./style.css";
export default function Public() {
  return (
    <div className="public">
      <b>Публичная оферта</b>
      <br />
      Настоящий документ – это публичная оферта (предложение) интернет-магазина
      «https://rarestore.ru/» о продаже товаров
      <br />
      <b>1.Общие положения</b>
      <br />
      1.1.Настоящая публичная оферта (далее - Оферта) является официальным
      предложением ИП Маркова Дениса Андреевича в адрес любого физического лица
      заключить с ИП Марков Денис Андреевич договор розничной купли-продажи
      товара на Сайте дистанционным образом на условиях, определенных в
      настоящем Договоре и содержит все существенные условия Оферты. <br />{" "}
      1.2.Заказ Покупателем товара, размещенного на Сайте, означает, что
      Покупатель согласен со всеми условиями настоящей Оферты, Политики
      конфиденциальности и Пользовательского соглашения.
      <br /> 1.3.Сайт имеет право вносить изменения в Оферту без уведомления
      Покупателя.
      <br /> 1.4.Срок действия Оферты не ограничен, если иное не указано на
      Сайте.
      <br /> 1.5.Сайт предоставляет Покупателю полную и достоверную информацию о
      товаре/услугах, включая информацию об основных потребительских свойствах
      товара, месте изготовления, а также информацию о гарантийном сроке и сроке
      годности товара на Сайте в разделе https://rarestore.ru/vozvrat-tovara/.
      <br /> <b>2. Предмет Оферты</b>
      <br />
      2.1.Сайт обязуется передать Покупателю товар, предназначенный для личного,
      семейного, домашнего или иного использования, не связанного с
      предпринимательской деятельностью, на основании размещенных Заказов, а
      Покупатель обязуется принять и оплатить Товар на условиях настоящей
      Оферты. <br />
      2.2.Наименование, цена, количество товара, а также прочие необходимые
      условия Оферты определяются на основании сведений, предоставленных
      Покупателем при оформлении заказа.
      <br /> 2.3.Право собственности на заказанные товары переходит к Покупателю
      с момента фактической передачи товара Покупателю и оплаты последним полной
      стоимости товара. Риск его случайной гибели или повреждения товара
      переходит к Покупателю с момента фактической передачи товара Покупателю.
      <br /> <b>3.Стоимость товара</b>
      <br />
      2.1.Цены на товар определяются Продавцом в одностороннем бесспорном
      порядке и указываются на страницах интернет-магазина, расположенного по
      интернет-адресу: https://rarestore.ru/. <br />
      2.2.Цена товара указывается в рублях Российской Федерации и включает в
      себя налог на добавленную стоимость. <br />
      2.3.Окончательная цена товара определяется последовательным действием на
      цену товара скидок по следующему порядку: • Акционная скидка • Скидка по
      промокоду • Скидка постоянного Покупателя <br />
      2.4.Расчеты между Сайтом и Покупателем за товар производятся способами,
      указанными на Сайте в разделе – https://rarestore.ru/dostavka/.
      <br /> <b>4.Момент заключения Оферты</b>
      <br />
      4.1.Акцептом настоящей Оферты (договора) является оформление Покупателем
      заказа на товар в соответствии с условиями настоящей Оферты. Оформление
      Покупателем заказа на товар производится путем совершения действий
      указанных в разделе «Сервис и помощь» пункт «Как сделать заказ».
      <br />
      4.2.Акцептируя настоящую Оферту, Покупатель выражает согласие в том, что:
      - регистрационные данные (в том числе персональные данные) указаны им
      добровольно; - регистрационные данные (в том числе персональные данные)
      передаются в электронной форме по каналам связи сети «Интернет»; -
      регистрационные данные (в том числе персональные данные) переданы Сайту
      для реализации целей, указанных в настоящей Оферте, Политике
      конфиденциальности, Пользовательском соглашении и могут быть переданы
      третьим лицам, для реализации целей, указанных в настоящей Оферте; -
      регистрационные данные (в том числе персональные данные) могут быть
      использованы Сайтом в целях продвижения товаров и услуг, путем
      осуществления прямых контактов с Покупателем с помощью каналов связи; - в
      целях дополнительной защиты от мошеннических действий указанные
      Покупателем регистрационные данные (в том числе персональные данные) могут
      быть переданы банку, осуществляющему транзакции по оплате оформленных
      заказов; - данное Покупателем согласие на обработку его регистрационных
      данных (в том числе персональных данных) является бессрочным и может быть
      отозвано Покупателем или его законным представителем, подачей письменного
      заявления, переданного Сайту.
      <br /> <b>5.Возврат товара и денежных средств</b>
      <br />
      5.1.Возврат товара осуществляется в соответствии с Законом РФ «О защите
      прав потребителей».
      <br /> 5.2.Возврат денежных средств осуществляется посредством возврата
      стоимости оплаченного товара на банковскую карту или почтовым переводом.
      <br /> <b>6.Доставка товара</b>
      <br />
      6.1.Доставка товара Покупателю осуществляется в сроки, согласованные
      Сторонами при подтверждении заказа сотрудником Сайта.
      <br /> 6.2.При курьерской доставке товара Покупатель в реестре доставки
      ставит свою подпись напротив тех позиций товара, которые Покупатель
      приобрел. Данная подпись служит подтверждением того, что Покупатель не
      имеет претензий к комплектации товара, к количеству и внешнему виду
      товара.
      <br /> 6.3.После получения товара претензии к количеству, комплектности и
      виду товара не принимаются.
      <br /> <b>7.Срок действия Оферты</b>
      <br />
      7.1.Настоящая Оферта вступает в силу с момента ее акцепта Покупателем, и
      действует до момента отзыва акцепта публичной Оферты.
      <br /> <b>8.Дополнительные условия</b>
      <br />
      8.1.Сайт вправе переуступать либо каким-либо иным способом передавать свои
      права и обязанности, вытекающие из его отношений с Покупателем, третьим
      лицам.
      <br /> 8.2.Сайт и предоставляемые сервисы могут временно частично или
      полностью недоступны по причине проведения профилактических или иных работ
      или по любым другим причинам технического характера. Техническая служба
      Сайта имеет право периодически проводить необходимые профилактические или
      иные работы с предварительным уведомлением Покупателей или без такового.
      <br /> 8.3.К отношениям между Покупателем и Сайтом применяются положения
      Российского законодательства.
      <br /> 8.4.В случае возникновения вопросов и претензий со стороны
      Покупателя он должен обратиться к Сайту по телефону или иным доступным
      способом. Все возникающее споры стороны будут стараться решить путем
      переговоров, при недостижении соглашения спор будет передан на
      рассмотрение в судебный орган в соответствии с действующим
      законодательством РФ.
      <br /> 8.5.Признание судом недействительности какого-либо положения
      настоящего Соглашения не влечет за собой недействительность остальных
      положений.
      <br />
      <b>9.Реквизиты Сайта</b>
      <br />
      <p style={{ textAlign: "left" }}>ОГРН 323440000021884 </p>
      <p>ИНН 440117597949</p>
      <p>ИП Марков Денис Андреевич</p>
    </div>
  );
}
