import { useContext, useEffect, useState } from "react";

import Card from "../../Components/Card/Card";
import Slider from "../../Components/Slider";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import CardBody from "../../Components/CardBody/CardBody";
import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import AnimatedPage from "../../AnimatedPage";
import Tg from "../../Components/Tg";
export default function New({ setCorzine, choosenBrand }) {
  const [cards, setCards] = useState([]);
  const [info, setInfo] = useState();
  const [card, setCard] = useState("");
  const [infoCard, setInfoCard] = useState(false);
  const [brandSearch, setBrandSearch] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("key");
  const [numCards, setNumCards] = useState(12);
  const Navigate = useNavigate();
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(99999);
  const [arrShoes, setArrShoes] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("Все");

  const getCards = () => {
    fetch("https://comeback-team.ru/cardsApi/getCardsNew.php", {
      method: "POST",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCards(data);
      });
  };
  useEffect(() => {
    if (cards.length > 0) {
      let arr = [];
      arr.push("Все");
      for (let i = 0; i < cards.length; i++) {
        arr.push(cards[i].Description);
      }

      const uniqueNames = new Set(arr); // создаём коллекцию уникальных значений
      setArrShoes(Array.from(uniqueNames));
    }
  }, [cards]);
  const getInfo = () => {
    let formData = new FormData();
    formData.append("cat", myParam);
    fetch("https://comeback-team.ru/cardsApi/getBrandInfo.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setInfo(data[0]);
        if (data[0]) {
          setCard(data[0].images);
        }
      });
  };
  const [openCat, setOpenCat] = useState(false);
  useEffect(() => {
    getCards();
  }, []);
  useEffect(() => {
    getCards();
    getInfo();
  }, [choosenBrand]);

  return (
    <div className="brandTg">
      <div
        className="preview"
        style={{
          backgroundImage:
            'url("https://static.tildacdn.com/tild3233-6164-4431-b062-383863366431/carhartt-cover.jpg")',
        }}
      >
        <div>
          <Slide top>
            <p>Новинки</p>
          </Slide>
        </div>
      </div>
      <Tg></Tg>
      <div className="strel">
        <div>
          <span>Главная → Бренды →</span> Новинки
        </div>
        {/* <div style={{ display: "flex" }}>
          <p className="ccat">Категория:</p>
          <div className="categoryBrand">
            <div className="currentCat" onClick={() => setOpenCat(!openCat)}>
              {currentFilter}
            </div>
            {openCat && (
              <div className="listCat">
                {arrShoes &&
                  arrShoes.length > 0 &&
                  arrShoes.map((em, index) => {
                    if (em != currentFilter) {
                      return (
                        <div
                          onClick={() => {
                            setCurrentFilter(em);
                            setOpenCat(false);
                          }}
                          key={index}
                        >
                          {em}
                        </div>
                      );
                    }
                  })}
              </div>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="priceV"
            >
              <p>Цена:</p>
              <input
                type="text"
                placeholder="Руб."
                onChange={(e) => setMin(e.target.value)}
                value={min}
              />
            </div>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="priceV"
            >
              <p>―</p>
              <input
                type="text"
                placeholder="Руб."
                onChange={(e) => setMax(e.target.value)}
                value={max}
              />
            </div>
          </div>
          <input
            type="text"
            placeholder="Поиск по бренду"
            value={brandSearch}
            onChange={(e) => setBrandSearch(e.target.value)}
          />
        </div> */}
      </div>
      {infoCard && (
        <CardBody setCorzine={setCorzine} closeCard={setInfoCard}></CardBody>
      )}
      <div className="cardItems">
        {cards &&
          cards.length > 0 &&
          cards.map((em, index) => {
            if (
              em.Title.toLowerCase().includes(brandSearch.toLowerCase()) &&
              Number(em.Price) >= Number(min) &&
              Number(em.Price) <= Number(max) &&
              (em.Description == currentFilter ||
                (currentFilter == "Все" && index < numCards))
            ) {
              return (
                <div
                  key={index}
                  onClick={() => {
                    localStorage.setItem("tovar", JSON.stringify(em));
                    setInfoCard(true);
                  }}
                >
                  <Rotate bottom left>
                    <Card card={em} />
                  </Rotate>
                </div>
              );
            }
          })}
      </div>
      <div
        className="setCount"
        style={numCards > cards.length ? { display: "none" } : {}}
        onClick={() => setNumCards(numCards + 12)}
      >
        Показать еще
      </div>
      <div className="brandInfo">
        <div className="brandInfoImg">
          {info &&
            info.imagesDown &&
            info.imagesDown.split(" ").map((em, index) => {
              return (
                <div
                  key={index}
                  style={{ backgroundImage: "url(" + em + ")" }}
                ></div>
              );
            })}
        </div>
        <h4>{info && info.info}</h4>
      </div>
    </div>
  );
}
