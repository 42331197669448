import "./style.css";
export default function Dostavka() {
  return (
    <div className="dostavka">
      <div className="firstD">
        <div className="dinfo">
          <h4>Почта России или СДЭК</h4>
          <p>
            Мы стараемся отправлять посылки в день заказа. Среднее время
            доставки Почтой России или СДЭК от 4 до 7 дней.
            <br />
            <br />
            Стоимость доставки расчитывается по тарифам транспортной компании.
            Если сумма заказа больше 9 990 рублей, то доставка будет бесплатной.
            <br />
            <br />
            Есть возможность наложенного платежа при отправке СДЭК. Тогда
            доставка будет стоить по тарифам СДЭК и оплачивается в момент
            оформления заказа, а сумма товара получении посылки.
            <br />
            <br />
            Так же есть возможно привезти товар с примеркой на дом. Мы привезем
            3-4 позиции на примерку. Стоимость по Москве - 500 рублей. За МКАД
            обсуждается с менеджером отдельно.
          </p>
        </div>
        <img
          src="https://static.tildacdn.com/tild3835-3839-4934-b561-326239353330/a-cold-wall-converse.jpg"
          alt=""
        />
      </div>
      <div className="secondD">
        <img
          src="https://static.tildacdn.com/tild3664-6135-4835-a539-636564643866/off-white-nike-air-f.jpeg"
          alt=""
        />
        <div className="dinfo">
          <h4>Курьерская доставка</h4>
          <p>
            В Москве есть возможность доставки курьером с примеркой. Стоимость
            доставки - 500 рублей. Можем привезти несколько размеров на
            примерку. Если ничего не подойдет или не понравится - просто можете
            оставить товар курьер.
          </p>
        </div>
      </div>
    </div>
  );
}
