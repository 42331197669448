import { useEffect, useRef, useState } from "react";
import "./style.css";
import ok from "./ok.png";
import cloud from "./cloud.svg";
export default function Admin() {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [sale, setSale] = useState("");
  const [file, isFile] = useState(false);
  const [brand, setBrand] = useState("");
  const [size, setSize] = useState("");
  const [info, setInfo] = useState("");
  const [catParam, setCatParam] = useState();
  const [brandParam, setBrandParam] = useState();
  const fileName = useRef(null);
  const [fileList, setFileList] = useState(null);
  const [sizes, isSizes] = useState(false);
  const handleFileChange = (e) => {
    setFileList(e.target.files);
  };
  function addCard() {
    let formData = new FormData();
    let size;
    let pushSize = [];
    formData.append("name", name);
    formData.append("cat", category);
    formData.append("price", price);
    formData.append("sale", sale);
    formData.append("brand", brand);
    if (
      document.getElementsByClassName("getSize") &&
      document.querySelectorAll("input[class=getSize]:checked").length > 0
    ) {
      size = document.querySelectorAll("input[class=getSize]:checked");
      for (let i = 0; i < size.length; i++) {
        pushSize.push(size[i].value);
      }
      formData.append("size", JSON.stringify(pushSize));
    }

    formData.append("info", info);
    files.forEach((file) => {
      formData.append("file[]", file);
    });

    fetch("https://comeback-team.ru/cardsApi/addCard.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      if (response.status == 200) {
        notice();
        setCategory("");
        setBrand("");
        setName("");
        setPrice("");
        setSale("");
        setInfo("");
        isFile(false);
        getCategory();
        getBrand();
      }
    });
  }
  const notice = () => {
    let div = document.getElementsByClassName("addT")[0];
    div.style.top = "1vw";
    setTimeout(() => {
      div.style.top = "-7vw";
    }, 2000);
  };
  const getCategory = () => {
    fetch("https://comeback-team.ru/cardsApi/getCategory.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCatParam(data);
      });
  };
  const getBrand = () => {
    fetch("https://comeback-team.ru/cardsApi/getBrand.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setBrandParam(data);
      });
  };
  useEffect(() => {
    if (category != "" && category != "Сумки") {
      isSizes(true);
    } else {
      isSizes(false);
    }
  }, [category]);
  useEffect(() => {
    getCategory();
    getBrand();
  }, []);
  const files = fileList ? [...fileList] : [];
  return (
    <div>
      <div className="addT"> Товар добавлен</div>
      <div className="addcard">
        <p className="titleAdd">Добавить товар</p>
        <input
          type="text"
          value={name}
          placeholder="Название"
          className="inText"
          onChange={(e) => setName(e.target.value)}
        />
        <div className="category">
          <input
            type="text"
            className="inText"
            value={category}
            placeholder="Категория"
            onChange={(e) => setCategory(e.target.value)}
          />
          <div className="categoryT">
            {catParam &&
              catParam.length > 0 &&
              catParam.map((em, index) => {
                if (
                  em.toLowerCase().includes(category.toLowerCase()) &&
                  em.toLowerCase() != category.toLowerCase()
                )
                  return (
                    <div key={index} onClick={() => setCategory(em)}>
                      {em}
                    </div>
                  );
              })}
          </div>
        </div>

        <input
          type="text"
          value={price}
          placeholder="Цена"
          className="inText"
          onChange={(e) => setPrice(e.target.value)}
        />
        <input
          type="text"
          value={sale}
          placeholder="Скидка"
          className="inText"
          onChange={(e) => setSale(e.target.value)}
        />
        <input
          type="file"
          name="file"
          id="filesadd"
          onChange={(e) => {
            isFile(true);
            handleFileChange(e);
          }}
          multiple="multiple"
          ref={fileName}
          style={{ display: "none" }}
        />
        <label className="filesadd" htmlFor="filesadd">
          <p>Добавить изображения</p>
          <img src={file ? ok : cloud} alt="" />
        </label>
        <div className="category">
          <input
            type="text"
            value={brand}
            className="inText"
            placeholder="Бренд"
            onChange={(e) => setBrand(e.target.value)}
          />
          <div className="categoryT">
            {brandParam &&
              brandParam.length > 0 &&
              brandParam.map((em, index) => {
                if (
                  em.toLowerCase().includes(brand.toLowerCase()) &&
                  em.toLowerCase() != brand.toLowerCase()
                )
                  return (
                    <div key={index} onClick={() => setBrand(em)}>
                      {em}
                    </div>
                  );
              })}
          </div>
        </div>

        {sizes && (
          <div className="size">
            <div>
              <p>S</p>
              <input
                type="checkbox"
                className="getSize"
                name=""
                id=""
                value="S"
              />
            </div>
            <div>
              <p>M</p>
              <input
                type="checkbox"
                className="getSize"
                name=""
                id=""
                value="M"
              />
            </div>
            <div>
              <p>L</p>
              <input
                type="checkbox"
                className="getSize"
                name=""
                id=""
                value="L"
              />
            </div>
            <div>
              <p>XL</p>
              <input
                type="checkbox"
                className="getSize"
                name=""
                id=""
                value="XL"
              />
            </div>
            <div>
              <p>52</p>
              <input
                type="checkbox"
                className="getSize"
                name=""
                id=""
                value="52"
              />
            </div>
            <div>
              <p>54</p>
              <input
                type="checkbox"
                className="getSize"
                name=""
                id=""
                value="54"
              />
            </div>
          </div>
        )}

        <textarea
          name=""
          placeholder="Информация"
          value={info}
          onChange={(e) => setInfo(e.target.value)}
          id=""
          cols="30"
          rows="7"
        ></textarea>
        <div className="save" onClick={() => addCard()}>
          Сохранить
        </div>
      </div>
    </div>
  );
}
