import { useContext, useEffect, useState } from "react";
import "./style.css";
import Card from "../../Components/Card/Card";
import Slider from "../../Components/Slider";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import CardBody from "../../Components/CardBody/CardBody";
import Rotate from "react-reveal/Rotate";
import Tg from "../../Components/Tg";
import { Zoom } from "react-reveal";
import Bot from "../../Components/Bot";
export default function Category({ corzine, setCorzine, choosenCategory }) {
  const [cards, setCards] = useState([]);
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("key");
  const [infoCard, setInfoCard] = useState(false);
  const [brandSearch, setBrandSearch] = useState("");
  const [numCards, setNumCards] = useState(12);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(99999);
  const getCards = () => {
    let formData = new FormData();
    formData.append("cat", myParam);
    if (
      myParam == "Кроссовки" ||
      myParam == "Ботинки" ||
      myParam == "Сандали" ||
      myParam == "Тактические ботинки" ||
      myParam == "Полуботинки" ||
      myParam == "Шлепанцы" ||
      myParam == "Вся обувь"
    ) {
      fetch("https://comeback-team.ru/cardsApi/getCardsCatShoes.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setCards(data);
        });
    } else {
      fetch("https://comeback-team.ru/cardsApi/getCardsCat.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setCards(data);
        });
    }
  };
  useEffect(() => {
    getCards();
  }, []);
  useEffect(() => {
    getCards();
  }, [choosenCategory]);
  return (
    <div className="categoryTg">
      <div className="preview">
        <div>
          <p>{myParam}</p>
        </div>
      </div>
      <Tg></Tg>
      <div className="strel">
        <div>
          <span>Главная → Одежда →</span> {myParam}
        </div>
      </div>
      {infoCard && (
        <CardBody setCorzine={setCorzine} closeCard={setInfoCard}></CardBody>
      )}
      <div className="cardItems">
        {cards &&
          cards.length > 0 &&
          cards.map((em, index) => {
            if (index < numCards) {
              return (
                <div
                  onClick={() => {
                    localStorage.setItem("tovar", JSON.stringify(em));
                    setInfoCard(true);
                  }}
                >
                  <Zoom>
                    <Card key={index} card={em} />
                  </Zoom>
                </div>
              );
            }
          })}
      </div>
      <div
        className="setCount"
        style={numCards > cards.length ? { display: "none" } : {}}
        onClick={() => setNumCards(numCards + 12)}
      >
        Показать еще
      </div>
      {!localStorage.idTelegram && <Bot></Bot>}
    </div>
  );
}
