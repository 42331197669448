import { useEffect, useRef, useState } from "react";
import Brand from "../../Components/Brand";
import Card from "../../Components/Card/Card";
import Header from "../../Components/Header";

import img1 from "../../Components/brandPhoto/st.jpg";
import img2 from "../../Components/brandPhoto/st2.jpg";
import test from "./1.jpg";
import Zoom from "react-reveal/Zoom";
import two from "./2.jpg";
import three from "./3.jpg";
import "./style.css";
import wtaps from "./wtaps.jpg";
import noah from "./noah.jpg";
import BrandType from "../../Components/BrandType";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import Liner from "../../Components/Liner";

import asycs from "./asycs.jpg";
import CardBody from "../../Components/CardBody/CardBody";
import Tg from "../../Components/Tg";
import Bot from "../../Components/Bot";
export default function Main({ corzine, setCorzine }) {
  const [cards, setCards] = useState([]);
  const Navigate = useNavigate();
  const [currentCard, setCurrentCard] = useState("");
  const [ZoomrNum, setZoomrNum] = useState(0);
  const [currentSize, setCurrentSize] = useState("");
  const [infoCard, setInfoCard] = useState(false);
  const scrollRef = useRef();
  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }
  const getCards = () => {
    fetch("https://comeback-team.ru/cardsApi/getCards.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCards(data);
      });
  };

  useEffect(() => {
    getCards();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mainCont">
      <div className="headerMain">
        <div className="seeMe">
          <img src={three} alt="" />
        </div>

        <div className="seeMe">
          <img src={test} alt="" />
        </div>
        <div className="seeMe">
          <img src={two} alt="" />
        </div>

        <div className="rareContent">
          <h2>RARE</h2>

          <p>
            Концептуальный магазин представляет в Москве дизайнерские решения из
            США, Европы и Японии, оказавших влияние на современные тенденции в
            мире искусства и моды.
          </p>

          <div
            className="hots"
            onClick={() => scrollRef.current.scrollIntoView()}
          >
            КАТАЛОГ
          </div>
        </div>
      </div>
      {infoCard && (
        <CardBody closeCard={setInfoCard} setCorzine={setCorzine}></CardBody>
      )}
      {document.body.clientWidth < 640 && (
        <div className="mobileRare">
          <div className="rareContent">
            <h2>RARE</h2>

            <p>
              Концептуальный магазин представляет в Москве дизайнерские решения
              из США, Европы и Японии, оказавших влияние на современные
              тенденции в мире искусства и моды.
            </p>

            <div
              className="hots"
              onClick={() => scrollRef.current.scrollIntoView()}
            >
              КАТАЛОГ
            </div>
          </div>
        </div>
      )}
      <Brand />
      <div className="cardItems" ref={scrollRef}>
        {cards &&
          cards.length > 0 &&
          cards.map((em, index) => {
            if (index < 12) {
              return (
                <Zoom key={index}>
                  <div style={{ display: "inline-block" }} className="itmeH">
                    <div
                      style={{ display: "inline-block" }}
                      onClick={() => {
                        localStorage.setItem("tovar", JSON.stringify(em));
                        setInfoCard(true);
                      }}
                    >
                      <Card card={em} />
                    </div>
                  </div>
                </Zoom>
              );
            } else {
              return;
            }
          })}
      </div>
      <Tg />
      <BrandType
        img1={
          "https://static.tildacdn.com/tild3664-3965-4235-b366-623765633663/360141-Brown-4ce5faa.jpeg"
        }
        title1="CARHARTT WIP"
        title2="WTAPS"
        img2={wtaps}
      />
      <Liner />
      <div className="cardItems">
        {cards &&
          cards.length > 0 &&
          cards.map((em, index) => {
            if (index >= 12 && index < 24) {
              return (
                <Zoom key={index}>
                  <div style={{ display: "inline-block" }} className="itmeH">
                    <div
                      style={{ display: "inline-block" }}
                      onClick={() => {
                        localStorage.setItem("tovar", JSON.stringify(em));
                        setInfoCard(true);
                      }}
                    >
                      <Card card={em} />
                    </div>
                  </div>
                </Zoom>
              );
            } else {
              return;
            }
          })}
      </div>{" "}
      <Tg />
      <BrandType
        img1={
          "https://static.tildacdn.com/tild3262-6566-4533-b963-303266373238/https3A2F2Fhypebeast.jpg"
        }
        title1="SALOMON"
        title2="ASICS"
        img2={asycs}
      />
      <Liner />
      <div className="cardItems">
        {cards &&
          cards.length > 0 &&
          cards.map((em, index) => {
            if (index >= 24 && index < 36) {
              return (
                <Zoom key={index}>
                  <div style={{ display: "inline-block" }} className="itmeH">
                    <div
                      style={{ display: "inline-block" }}
                      onClick={() => {
                        localStorage.setItem("tovar", JSON.stringify(em));
                        setInfoCard(true);
                      }}
                    >
                      <Card card={em} />
                    </div>
                  </div>
                </Zoom>
              );
            } else {
              return;
            }
          })}
      </div>{" "}
      <Tg />
      <BrandType
        img1={
          "https://static.tildacdn.com/tild3262-6138-4436-a230-393165666139/Stussy-Images-4.jpg"
        }
        title1="STUSSY"
        title2="NOAH"
        img2={noah}
      />
      <Liner />
      <div className="cardItems">
        {cards &&
          cards.length > 0 &&
          cards.map((em, index) => {
            if (index >= 36 && index < 48) {
              return (
                <Zoom key={index}>
                  <div style={{ display: "inline-block" }} className="itmeH">
                    <div
                      style={{ display: "inline-block" }}
                      onClick={() => {
                        localStorage.setItem("tovar", JSON.stringify(em));
                        setInfoCard(true);
                      }}
                    >
                      <Card card={em} />
                    </div>
                  </div>
                </Zoom>
              );
            } else {
              return;
            }
          })}
      </div>{" "}
      {!localStorage.idTelegram && <Bot></Bot>}
      <Tg />
      <BrandType
        img1={
          "https://www.highsnobiety.com/static-assets/dato/1682631783-nike-acg-holiday-20-01.jpg"
        }
        title1="NIKE ACG"
        title2="HOKA ONE ONE"
        img2={
          "https://static.tildacdn.com/tild3636-3434-4330-b839-633263626630/engineered-garments-.jpg"
        }
      />
      <Liner />
    </div>
  );
}
