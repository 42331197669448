import { useState, useContext, useEffect } from "react";
import "./style.css";
import { Liked } from "../../Liked";
import loop from "./loop.png";
import { Add } from "../../add";
import { Promo } from "../../Promo";

export default function Card({ card }) {
  const [prom, setProm] = useContext(Promo);
  let cardImg = card.Photo.trim().split(" ");
  const [like, isLike] = useState(false);
  const [currentLoop, setCurrentLoop] = useState();
  const [corzine, setCorzine] = useContext(Add);
  let photo = card.Description;
  const [likeSet, isLikeSet] = useContext(Liked);
  const [openSize, setOpenSize] = useState(false);
  useEffect(() => {
    likeSet &&
      likeSet.length > 0 &&
      likeSet.map((em) => {
        if (card.ID == em.ID) {
          isLike(true);
        }
      });
  }, [likeSet]);
  function closeMe() {}
  return (
    <div className="card">
      <div
        className="like"
        onClick={(e) => {
          if (like) {
            e.stopPropagation();
            isLikeSet(likeSet.filter((item) => item.ID !== card.ID));
            isLike(!like);
          } else {
            e.stopPropagation();
            isLikeSet((likeSet) => [...likeSet, card]);
            isLike(!like);
          }
        }}
        style={currentLoop == card.ID ? { background: "e3e8e6" } : {}}
      >
        {" "}
        {like ? (
          <svg
            width="21"
            height="18"
            viewBox="0 0 21 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 6.32647C20 11.4974 10.5 17 10.5 17C10.5 17 1 11.4974 1 6.32647C1 -0.694364 10.5 -0.599555 10.5 5.57947C10.5 -0.599555 20 -0.507124 20 6.32647Z"
              stroke="black"
              stroke-linejoin="round"
              style={{ fill: "#ed5952", stroke: "#ed5952" }}
            ></path>
          </svg>
        ) : (
          <svg
            width="21"
            height="18"
            viewBox="0 0 21 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 6.32647C20 11.4974 10.5 17 10.5 17C10.5 17 1 11.4974 1 6.32647C1 -0.694364 10.5 -0.599555 10.5 5.57947C10.5 -0.599555 20 -0.507124 20 6.32647Z"
              stroke="black"
              stroke-linejoin="round"
            ></path>
          </svg>
        )}
      </div>
      <div
        className="loop"
        onClick={(e) => {
          e.stopPropagation();
          if (currentLoop == card.ID) {
            setCurrentLoop("");
          } else {
            setCurrentLoop(card.ID);
          }
        }}
      >
        <img src={loop} alt="" />
      </div>
      <div className="hei">
        <img
          style={
            photo == "Кроссовки" ||
            photo == '"Тактические ботинки"' ||
            photo == "Полуботинки" ||
            photo == "Ботинки"
              ? { width: "100%", height: "auto" }
              : {}
          }
          src={cardImg && cardImg.length > 0 ? cardImg[0].replace('"', "") : ""}
          className={currentLoop == card.ID && "change"}
          alt=""
        />
      </div>

      <div className="cardInfo">
        <p>{card.Title}</p>
        <span className="relate">{card.Description} </span>
        <div className="price">
          {prom ? Math.round(card.Price - (card.Price / 100) * 5) : card.Price}
          р.{" "}
          <span>
            {" "}
            {Number(card.PriceOld) != false && card.PriceOld + " р."}
          </span>
        </div>
      </div>
      {document.body.clientWidth > 640 && (
        <div
          className="goCorz"
          onClick={(e) => {
            e.stopPropagation();
            if (card.Type == "Аксессуары" || card.Type == "Сумки") {
              let item = card;
              item.Quantity = "";

              setCorzine((corzine) => [...corzine, item]);
            }
          }}
        >
          <div>В корзину</div>
          {card.Type != "Аксессуары" && card.Type != "Сумки" && (
            <div className="sizesCard">
              <span>Размер: </span>
              {card.Editions.substr(1, card.Editions.length - 2)
                .trim()
                .split(",")
                .map((em, index) => {
                  return (
                    <div
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        let item = card;
                        item.Quantity = em;

                        setCorzine((corzine) => [...corzine, item]);
                      }}
                    >
                      {em}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
