export default function Oplata() {
  return (
    <div className="dostavka">
      <div className="firstD">
        <div className="dinfo">
          <h4>Оплата</h4>
          <p>
            • Наличными
            <br />
            <br />
            Такой способ оплаты возможен в магазине. Оплата производится только
            в российских рублях - национальной валюте. Чек, подтверждающий
            покупку, обязательно выдается каждому покупателю.
            <br /> <br />
            • Онлайн оплата картой Visa или MasterCard
            <br />
            <br />
            С помощью любой банковской карты Visa или MasterCard на сайте можно
            осуществить быструю оплату заказа. При оформлении покупки, выбрав
            способ «Оплата картой», Вы перейдете на страницу системы безопасных
            платежей банка, где Вам необходимо будет подтвердить оплату.
            <br />
            <br />
            • Наложенный платеж
            <br />
            <br />
            Оплата наложенным платежом осуществляется после проверки Вами
            посылки на целостность содержания, его надлежащее состояние, по
            прибытию ее на место назначения. Обратите внимание, что во время
            получения заказа, Вам необходимо будет подписать акт
            приема-передачи, который подтвердит тот факт, что вас лично все
            устраивает.
          </p>
        </div>
        <img
          src="https://static.tildacdn.com/tild6638-3833-4262-b138-663665646135/https3A2F2Fhypebeast.jpg"
          alt=""
        />
      </div>
    </div>
  );
}
