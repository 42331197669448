import Marquee from "react-fast-marquee";
import s from "./sh.png";
export default function Tg() {
  return (
    <div style={{ background: "black" }} className="pad">
      <Marquee>
        <div className="tme">
          <img src={s} alt="" />

          <a href="https://t.me/rarestoreru" target="_blank">
            {" "}
            @rarestoreru
          </a>
          <img src={s} alt="" />

          <a href="https://t.me/rarestoreru" target="_blank">
            {" "}
            @rarestoreru
          </a>
          <img src={s} alt="" />

          <a href="https://t.me/rarestoreru" target="_blank">
            {" "}
            @rarestoreru
          </a>
          <img src={s} alt="" />

          <a href="https://t.me/rarestoreru" target="_blank">
            {" "}
            @rarestoreru
          </a>
          <img src={s} alt="" />

          <a href="https://t.me/rarestoreru" target="_blank">
            {" "}
            @rarestoreru
          </a>
          <img src={s} alt="" />

          <a href="https://t.me/rarestoreru" target="_blank">
            {" "}
            @rarestoreru
          </a>
          <img src={s} alt="" />

          <a href="https://t.me/rarestoreru" target="_blank">
            {" "}
            @rarestoreru
          </a>
          <img src={s} alt="" />

          <a href="https://t.me/rarestoreru" target="_blank">
            {" "}
            @rarestoreru
          </a>
          <img src={s} alt="" />

          <a href="https://t.me/rarestoreru" target="_blank">
            {" "}
            @rarestoreru
          </a>
          <img src={s} alt="" />

          <a href="https://t.me/rarestoreru" target="_blank">
            {" "}
            @rarestoreru
          </a>
          <img src={s} alt="" />
          <a href="https://t.me/rarestoreru" target="_blank">
            {" "}
            @rarestoreru
          </a>
        </div>
      </Marquee>
    </div>
  );
}
