import phone from "./phone-svgrepo-com.svg";
import tg from "./icons8-телеграм-48.png";
import email from "./email.png";

import "./style.css";
import { Zoom } from "react-reveal";
export default function CcontactsComp() {
  return (
    <div className="conts">
      <Zoom>
        <div>
          <img src={phone} alt="" />
          <h4>Телефон</h4>
          <a href="tel:+7(985)8127862" target="_blank">
            +7 (985) 812 7862
          </a>
        </div>
      </Zoom>
      <Zoom>
        {" "}
        <div>
          <img src={tg} alt="" />
          <h4>Менеджер Telegram</h4>
          <a href="https://t.me/rarestoreru" target="_blank">
            t.me/rarestoreru
          </a>
        </div>
      </Zoom>
      <Zoom>
        {" "}
        <div>
          <img src={email} alt="" />
          <h4>Почта</h4>
          <a href="mailto:order@rarestore.ru" target="_blank">
            order@rarestore.ru
          </a>
        </div>
      </Zoom>
    </div>
  );
}
