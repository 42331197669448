import tg from "./chat.png";
import wt from "./wt.png";
import vk from "./tg.png";
import "./style.css";
import close from "./close.png";
import { useState } from "react";
import zvonok from "../../zvonok.png";
export default function Links() {
  const [viseble, isVisible] = useState(false);
  const [show, isShow] = useState(false);
  const [tel, setTel] = useState("");
  function setTele() {
    let txt = "Перезвонить по номеру " + tel;
    let form = new FormData();
    form.append("text", txt);
    form.append("parse_mode", "html");

    fetch(
      "https://api.telegram.org/bot6330231124:AAHvAq4zYBegf5a34GadMocXIX9WrBQMqFw/sendMessage?chat_id=-4056628299",
      {
        method: "post",
        body: form,
      }
    ).then((response) => {
      if (response.status == 200) {
        isShow(false);
        alert("Заявка отправлена, скоро перезвоним!");
      }
    });
  }
  return (
    <div className="sets">
      {show && (
        <div className="formZvon">
          <img src={close} alt="" onClick={() => isShow(false)} />
          <p>Мы перезвоним вам в течение 10 минут</p>
          <input
            type="tel"
            value={tel}
            onChange={(e) => setTel(e.target.value)}
            placeholder="+7(900)00-00-00"
          />
          <div className="sendZvon" onClick={() => setTele()}>
            Отправить
          </div>
        </div>
      )}
      {viseble && (
        <div className="messangers">
          <a href="https://wa.me/79858127862" target="_blank">
            {" "}
            <img src={wt} alt="" />
          </a>
          <a href="https://t.me/rarestore_manager" target="_blank">
            {" "}
            <img src={vk} alt="" />
          </a>
        </div>
      )}

      <div className="askMe" onClick={() => isVisible(!viseble)}>
        <img
          src={!viseble ? tg : close}
          alt=""
          style={viseble ? { width: "60%" } : {}}
        />
      </div>
      <div className="zvon">
        <p onClick={() => isShow(!show)}>Заказать звонок</p>{" "}
        <div className="askMe neer" onClick={() => isShow(!show)}>
          <img src={zvonok} alt="" style={viseble ? { width: "60%" } : {}} />
        </div>
      </div>
    </div>
  );
}
