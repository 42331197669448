import "./style.css";
import Marquee from "react-fast-marquee";
import acg from "./acg.png";
import arc from "./arc.png";
import asics from "./asics.png";
import carh from "./carhartt.png";
import esse from "./essentials.png";
import hoka from "./hoka.png";
import kith from "./kith.png";
import nike from "./nike.png";
import patag from "./patagonia.png";
import salom from "./salomon.png";
import styssy from "./stussy.jpg";
import wtaps from "./wtaps.png";
export default function Liner() {
  return (
    <div className="liner">
      <Marquee>
        <div className="linerEm">
          <img src={acg} alt="" />
        </div>
        <div className="linerEm">
          <img src={arc} alt="" />
        </div>
        <div className="linerEm">
          <img src={esse} alt="" />
        </div>
        <div className="linerEm">
          <img src={asics} alt="" />
        </div>
        <div className="linerEm">
          <img src={carh} alt="" />
        </div>
        <div className="linerEm">
          <img src={hoka} alt="" />
        </div>
        <div className="linerEm">
          <img src={kith} alt="" />
        </div>
        <div className="linerEm">
          <img src={nike} alt="" />
        </div>

        <div className="linerEm">
          <img src={patag} alt="" />
        </div>
        <div className="linerEm">
          <img src={salom} alt="" />
        </div>
        <div className="linerEm">
          <img src={styssy} alt="" />
        </div>
        <div className="linerEm">
          <img src={wtaps} alt="" />
        </div>
      </Marquee>
    </div>
  );
}
