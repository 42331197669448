import first from "./1.png";
import second from "./2.png";
import third from "./3.jpg";
import "./style.css";
import stars from "./stars.jpg";
import { Zoom } from "react-reveal";
export default function Reviews() {
  return (
    <div className="review">
      <Zoom left>
        <div className="Review">
          <img className="revImg" src={first} alt="" />
          <div>
            <p className="ufu">5/5</p>{" "}
            {/* <img className="revImgs" src={stars} alt="" /> */}
            <p>232 отзыва</p>
          </div>
          <article>
            <h4>IRINA</h4>
            <img className="revImgs" src={stars} alt="" />
            <p>
              Огромное спасибо продавцу!!! Куртка шикарная, , легкая комфортная
              и теплая, сын в восторге, снимать не хочет , сразу отправили,
              пришла в течение двух дней, куртка как и описали , просто огонь 🔥
            </p>
          </article>
          <article>
            <h4>Ольга</h4>
            <img className="revImgs" src={stars} alt="" />
            <p>Покупка оправдала ожидания.Спасибо продавцу 👍👍👍</p>
          </article>
          <article>
            <h4>Александр</h4>
            <img className="revImgs" src={stars} alt="" />
            <p>
              У ребят отличный сервис👍🏼 Кроссовки очень достойного качества, на
              мою стопу сели очень эргономично, буду тестировать временем!
            </p>
          </article>
          <a
            href="https://www.avito.ru/user/4f5665e610b6639a1c7d8a5db47268c2/profile/all/odezhda_obuv_aksessuary?gdlkerfdnwq=101&shopId=2505084&page_from=from_item_card&iid=2874803875&sellerId=4f5665e610b6639a1c7d8a5db47268c2"
            target="_blank"
          >
            Мы на авито
          </a>
        </div>
      </Zoom>{" "}
      <Zoom top>
        <div className="Review">
          <img className="revImg" src={second} alt="" />
          <div>
            <p className="ufu">4.7/5</p>{" "}
            {/* <img className="revImgs" src={stars} alt="" /> */}
            <p>28 отзывов</p>
          </div>
          <article>
            <h4>Милена Асламазова</h4>
            <img className="revImgs" src={stars} alt="" />
            <p>
              Покупала парню в подарок кроссовки и футболку. Ему очень
              понравилось! Одежда и обувь очень качественные. Удобный сайт и
              очень вежливый персонал в самом магазине. Обязательно вернёмся
              ещё!
            </p>
          </article>
          <article>
            <h4>Денис Редькин</h4>
            <img className="revImgs" src={stars} alt="" />
            <p>
              Супер магазин! Продавцы знают свое дело, кроссовки отличного
              качества, только положительные эмоции!
            </p>
          </article>
          <article>
            <h4>Егор К.</h4>
            <img className="revImgs" src={stars} alt="" />
            <p>
              Покупал здесь HOKA ONE ONE. Выбор большой, продавцы вежливые. Буду
              заезжать еще
            </p>
          </article>
          <a
            href="https://yandex.ru/maps/org/rare/48647380075/?ll=37.610440%2C55.716904&z=17"
            target="_blank"
          >
            Мы в яндексе
          </a>
        </div>{" "}
      </Zoom>
      <Zoom right>
        <div className="Review">
          <img className="revImg" src={third} alt="" />
          <div>
            <p className="ufu">5/5</p>{" "}
            {/* <img className="revImgs" src={stars} alt="" /> */}
            <p>64 отзыва</p>
          </div>

          <article>
            <h4>deadLover</h4>
            <img className="revImgs" src={stars} alt="" />
            <p>
              Качество отличное! Продавец вежливый и клиентоориентированный.
              Ответил на все вопросы, дал замеры по футболке и отправил курьера.
              Рекомендую 👍
            </p>
          </article>
          <article>
            <h4>Константин</h4>
            <img className="revImgs" src={stars} alt="" />
            <p>
              Отличное качество, удобное местоположение, видно расположение к
              клиенту в приятном отношении. Только положительные эмоции.
              Рекомендую.
            </p>
          </article>
          <article>
            <h4>Олег</h4>
            <img className="revImgs" src={stars} alt="" />
            <p>
              Быстро отвечали, всё по делу, приехал в магазин, помогли подобрать
              размер, приятный продавец. Всем рекомендую.
            </p>
          </article>
          <a href="https://t.me/rarestoreru" target="_blank">
            Мы в телеграм
          </a>
        </div>{" "}
      </Zoom>
    </div>
  );
}
