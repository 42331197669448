import "./App.css";
import { Routes, Route } from "react-router-dom";
import Main from "./Pages/Main";
import Navbar from "./Components/Navbar";
import { useEffect, useRef, useState } from "react";
import { routes } from "./routes";
import Admin from "./Pages/Admin/Admin";
import Footer from "./Components/Footer";
import Category from "./Pages/Category";
import { CategoryType } from "./Category";
import BrandPage from "./Pages/Brand";
import zvonok from "./zvonok.png";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import Summ from "./Components/Summ";
import Header from "./Components/Header";
import Links from "./Components/Links";
import About from "./Components/About";
import CardBody from "./Components/CardBody/CardBody";
import Sale from "./Pages/Sale";
import Dostavka from "./Pages/Dostavka";
import Oplata from "./Pages/Oplata";
import Public from "./Pages/Public";
import corz from "./corz.png";
import { Context } from "./Context";
import close from "./close.png";
import { Liked } from "./Liked";
import Slide from "react-reveal/Slide";
import { AnimatePresence } from "framer-motion";
import Tg from "./Components/Tg";
import CcontactsComp from "./Components/ContactsComp";
import Magazine from "./Components/Magazine";
import New from "./Pages/New";
import { Promo } from "./Promo";
import { Zoom } from "react-reveal";
import Order from "./Pages/Order/Order";
import { Add } from "./add";
import Reviews from "./Pages/Reviews";
import img1 from "./IMAGES/1.jpg";
import img2 from "./IMAGES/2.jpg";
import img3 from "./IMAGES/3.jpg";
import img4 from "./IMAGES/4.jpg";
import img5 from "./IMAGES/5.jpg";
import img6 from "./IMAGES/6.jpg";
import img7 from "./IMAGES/7.jpg";
import img8 from "./IMAGES/8.jpg";
import TelegramPage from "./Pages/TelegramPage";
function App() {
  const [navbarOpened, isNavbarOpened] = useState(false);
  const [choosenCategory, setChoosenCategory] = useState("");
  const [choosenBrand, setChoosenBrand] = useState("");
  const [openCorz, setOpenCorz] = useState(false);
  const [likeArr, setLikeArr] = useState([]);
  const [likeOpen, isLikeOpen] = useState(false);
  const [card, setInfoCard] = useState(false);
  const [promo, setPromo] = useState(false);
  const [globalSearch, setGlobalSearch] = useState([]);
  function deleteLike(id) {
    setLikeArr(likeArr.filter((item) => item.ID !== id));
  }
  useEffect(() => {
    if (likeArr.length == 0) {
      isLikeOpen(false);
    }
  }, [likeArr]);
  useEffect(() => {
    if (localStorage.like) {
      setLikeArr(JSON.parse(localStorage.like));
    }
  }, []);

  useEffect(() => {
    localStorage.like = JSON.stringify(likeArr);
  }, [likeArr]);

  const [corzine, setCorzine] = useState(
    localStorage.corz ? JSON.parse(localStorage.corz) : []
  );
  const urlParams = new URLSearchParams(window.location.search);
  const myParams = urlParams.get("item");
  useEffect(() => {
    if (myParams) {
      let formData = new FormData();
      formData.append("ID", myParams);
      fetch("https://comeback-team.ru/getCard.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          localStorage.setItem("tovar", JSON.stringify(data[0]));
          setInfoCard(true);
        });
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [choosenCategory, choosenBrand]);
  useEffect(() => {
    localStorage.setItem("corz", JSON.stringify(corzine));
    if (
      document.getElementsByClassName("circleCorz") &&
      corzine.length &&
      document.body.clientWidth > 640
    ) {
      document.getElementsByClassName("circleCorz")[0].style =
        "animation: zoos 0.5s forwards";

      setTimeout(() => {
        if (document.getElementsByClassName("circleCorz")) {
          document.getElementsByClassName("circleCorz")[0].style = "";
        }
      }, 600);
    }
  }, [corzine]);
  useEffect(() => {
    let tg = window.Telegram.WebApp;

    if (tg && tg.initDataUnsafe.user) {
      let formData = new FormData();
      let date = new Date();
      localStorage.setItem("tgName", tg.initDataUnsafe.user.first_name);
      formData.append("id", tg.initDataUnsafe.user.id);
      formData.append("idSt", 23);
      formData.append("usernameTg", tg.initDataUnsafe.user.username);
      formData.append(
        "username",
        tg.initDataUnsafe.user.first_name +
          " " +
          tg.initDataUnsafe.user.last_name
      );
      formData.append("date", date.toString());
      setTimeout(() => {
        fetch("https://comeback-team.ru/authMainRare.php", {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data.firstBuy == "0") {
              setPromo(true);
            }
            localStorage.setItem("idTelegram", data.idTg);
          });
      }, 2000);
    }
  });

  return (
    <div className="App">
      <Promo.Provider value={[promo, setPromo]}>
        <Add.Provider value={[corzine, setCorzine]}>
          <Header
            isNavbarOpened={isNavbarOpened}
            setChoosenCategory={setChoosenCategory}
            setChoosenBrand={setChoosenBrand}
            navbarOpened={navbarOpened}
            countSumm={corzine.length}
            setOpenCorz={setOpenCorz}
            global={globalSearch}
            setGlobal={setGlobalSearch}
          ></Header>
          {globalSearch && globalSearch.length > 0 && (
            <div className="searchMe">
              {globalSearch.map((em, index) => {
                return (
                  <div
                    className="corz_em corzSet"
                    key={index}
                    onClick={() => {
                      localStorage.setItem("tovar", JSON.stringify(em));
                      setInfoCard(true);
                    }}
                  >
                    <div className="corz_info">
                      <img
                        className="fixImg"
                        // style={{
                        //   backgroundImage: `url(${
                        //     em.Photo.replace('"', "").split(" ")[0]
                        //   })`,
                        // }}
                        src={em.Photo.replace('"', "").split(" ")[0]}
                      ></img>

                      <div className="corz_body">
                        <p className="corz_body_title">{em.Title}</p>

                        <div className="corz_body_sumprice">
                          <p className="corz_body_sum">{em.Price} руб.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <Links />
          <AnimatePresence wait>
            <div className="container" style={{ width: "100%" }}>
              {corzine.length > 0 && document.body.clientWidth > 640 && (
                <div className="circleCorz" onClick={() => setOpenCorz(true)}>
                  <img src={corz} />
                  <div className="redNum">{corzine.length}</div>
                </div>
              )}
              {likeArr.length > 0 && (
                <div className="likeButton" onClick={() => isLikeOpen(true)}>
                  <svg
                    width="21"
                    height="18"
                    viewBox="0 0 21 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 6.32647C20 11.4974 10.5 17 10.5 17C10.5 17 1 11.4974 1 6.32647C1 -0.694364 10.5 -0.599555 10.5 5.57947C10.5 -0.599555 20 -0.507124 20 6.32647Z"
                      stroke="black"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                  <div className="redNum">{likeArr.length}</div>
                </div>
              )}

              {likeOpen && (
                <Slide right duration={500}>
                  <div className="likedEms">
                    <div className="flexM">
                      <h4>Избранное</h4>
                      <img
                        src={close}
                        className="krest"
                        onClick={() => isLikeOpen(false)}
                      ></img>
                    </div>

                    <div className="likedEmsArr">
                      {likeArr &&
                        likeArr.length &&
                        likeArr.map((em, index) => {
                          return (
                            <div
                              className="corz_em corzSet"
                              key={index}
                              onClick={() => {
                                localStorage.setItem(
                                  "tovar",
                                  JSON.stringify(em)
                                );
                                setInfoCard(true);
                              }}
                            >
                              <div className="corz_info">
                                <div
                                  className="fixImg"
                                  style={{
                                    backgroundImage: `url(${
                                      em.Photo.replace('"', "").split(" ")[0]
                                    })`,
                                  }}
                                ></div>

                                <div className="corz_body">
                                  <p className="corz_body_title">{em.Title}</p>

                                  <div className="corz_body_sumprice">
                                    <p className="corz_body_sum">
                                      {em.Price} руб.
                                    </p>
                                  </div>
                                  <p
                                    className="deleteLike"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      deleteLike(em.ID);
                                    }}
                                  >
                                    Удалить
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </Slide>
              )}
              {openCorz && (
                <Summ
                  setCorzine={setCorzine}
                  setOpenCorz={setOpenCorz}
                  items={corzine}
                />
              )}
              <Liked.Provider value={[likeArr, setLikeArr]}>
                <Context.Provider value={[openCorz, setOpenCorz]}>
                  {card && (
                    <CardBody closeCard={setInfoCard} setCorzine={setCorzine} />
                  )}
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Main corzine={corzine} setCorzine={setCorzine} />
                      }
                    />
                    <Route path={routes.order} element={<Order />} />
                    <Route
                      path={routes.tovar}
                      element={
                        <CardBody
                          corzine={corzine}
                          setCorzine={setCorzine}
                          setOpenCorz={setOpenCorz}
                        />
                      }
                    />
                    <Route path={routes.about} element={<About />} />
                    <Route
                      path={routes.new}
                      element={
                        <New
                          corzine={corzine}
                          setCorzine={setCorzine}
                          choosenBrand={choosenBrand}
                        />
                      }
                    />
                    <Route
                      path={routes.telegramPage}
                      element={
                        <TelegramPage
                          setCorzine={setCorzine}
                          choosenBrand={choosenBrand}
                        />
                      }
                    />
                    <Route path={routes.admin} element={<Admin />} />
                    <Route path={routes.dostavka} element={<Dostavka />} />
                    <Route path={routes.oplata} element={<Oplata />} />
                    <Route path={routes.public} element={<Public />} />
                    <Route
                      path={routes.categories}
                      element={
                        <Category
                          corzine={corzine}
                          setCorzine={setCorzine}
                          choosenCategory={choosenCategory}
                        />
                      }
                    />
                    <Route
                      path={"brand/:myParam"}
                      element={
                        <BrandPage
                          corzine={corzine}
                          setCorzine={setCorzine}
                          choosenBrand={choosenBrand}
                        />
                      }
                    />
                    <Route
                      path={routes.sales}
                      element={<Sale setCorzine={setCorzine} />}
                    />
                  </Routes>
                </Context.Provider>
              </Liked.Provider>
              <h2 className="reviewTitle">Отзывы о нашем магазине</h2>
              <Reviews></Reviews>
              <CcontactsComp />
              <Magazine />
              <Tg></Tg>
              <YMaps>
                <div className="map2" style={{ marginTop: "1vw" }}>
                  <Map
                    width="100%"
                    height="500px"
                    defaultState={{
                      center: [55.716891956421556, 37.61082037368005],
                      zoom: 20,
                    }}
                  >
                    <Placemark
                      defaultGeometry={[55.716891956421556, 37.61082037368005]}
                    />
                  </Map>
                </div>
              </YMaps>
              <Tg></Tg>
              {/* <div
            className="promo"
            style={{
              backgroundImage:
                'url("https://static.tildacdn.com/tild6535-3831-4335-b861-626164613133/Gascoyne_ACG_HO20.jpg")',
            }}
          >
            <Slide delay={3000} duration={2000}>
              <div>
                Активируй промокод: THERARE <br />
                -5% на первый заказ
              </div>
            </Slide>
          </div> */}
              <div className="dash">#RARE</div>
              <div className="inst">
                {document.body.clientWidth > 640 && (
                  <div className="sectionS">
                    <a
                      target="_blank"
                      href="https://instagram.com/rarestore_ru"
                      style={{
                        backgroundImage: "url(" + img1 + ")",
                      }}
                    ></a>
                    <a
                      target="_blank"
                      href="https://instagram.com/rarestore_ru"
                      style={{
                        backgroundImage: "url(" + img2 + ")",
                      }}
                    ></a>
                    <a
                      target="_blank"
                      href="https://instagram.com/rarestore_ru"
                      style={{
                        backgroundImage: "url(" + img3 + ")",
                      }}
                    ></a>
                    <a
                      target="_blank"
                      href="https://instagram.com/rarestore_ru"
                      style={{
                        backgroundImage: "url(" + img4 + ")",
                      }}
                    ></a>
                  </div>
                )}
                <div className="sectionS">
                  <a
                    target="_blank"
                    href="https://instagram.com/rarestore_ru"
                    style={{
                      backgroundImage: "url(" + img5 + ")",
                    }}
                  ></a>
                  <a
                    target="_blank"
                    href="https://instagram.com/rarestore_ru"
                    style={{
                      backgroundImage: "url(" + img6 + ")",
                    }}
                  ></a>
                  <a
                    target="_blank"
                    href="https://instagram.com/rarestore_ru"
                    style={{
                      backgroundImage: "url(" + img7 + ")",
                    }}
                  ></a>
                  <a
                    target="_blank"
                    href="https://instagram.com/rarestore_ru"
                    style={{
                      backgroundImage: "url(" + img8 + ")",
                    }}
                  ></a>
                </div>
              </div>

              <Tg></Tg>
              <Footer />
            </div>
          </AnimatePresence>
        </Add.Provider>
      </Promo.Provider>
    </div>
  );
}

export default App;
