import "./style.css";
import test from "./1.jpg";
import Flip from "react-reveal/Flip";
export default function About() {
  return (
    <div className="abouts" id="contacts">
      <Flip left>
        <div className="slideAbout">
          <div className="leftSlide"></div>
          <img src={test} alt="" />
          <div className="rightSlide"></div>
        </div>
      </Flip>
      <div className="aboutInfo">
        <Flip left cascade>
          <h4>МАГАЗИН</h4>
        </Flip>
        <div className="aboutLine"></div>
        <Flip delay={100} left cascade>
          <div className="adress">Москва, ул. Шухова 18</div>
        </Flip>
        <p>
          Концептуальный магазин представляет в Москве дизайнерские решения из
          США, Европы и Японии, оказавших влияние на современные тенденции в
          мире искусства и моды.
          <br />
          <br />
          Мы предоставляем реплику фабричного качества, продукция создается на
          тех же фабриках Азии и Вьетнама, что и оригинальная, с сохранением
          всех технологий производства и материалов.
        </p>
      </div>
    </div>
  );
}
