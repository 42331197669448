export const routes = {
  admin: "/admin",
  categories: "/categories",
  brand: "/brand/:id",
  tovar: "/tovar",
  sales: "/sales",
  dostavka: "/dostavka",
  oplata: "/oplata",
  public: "/public",
  about: "/about",
  new: "/new",
  order: "/order",
  telegramPage: "/telegramPage",
};
