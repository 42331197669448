import { useNavigate } from "react-router-dom";
import "./style.css";
import test from "./test.jpg";
import Zoom from "react-reveal/Zoom";
import { routes } from "../../routes";
export default function BrandType({ img1, img2, title1, title2 }) {
  const Navigate = useNavigate();
  return (
    <div>
      <div className="twoImg">
        <Zoom left>
          <div
            className="twoFirst"
            style={{ backgroundImage: "url(" + img1 + ")" }}
            onClick={() => Navigate("/brand/" + title1)}
          >
            {/* <img src={img1} alt="" /> */}
            <div className="filterD"></div>
            <div className="typeNext">{title1}</div>
          </div>
        </Zoom>
        <Zoom right>
          <div
            className="twoFirst"
            style={{ backgroundImage: "url(" + img2 + ")" }}
            onClick={() => Navigate("/brand/" + title2)}
          >
            <div className="filterD"></div>
            {/* <img src={img2} alt="" /> */}
            <div className="typeNext">{title2}</div>
          </div>
        </Zoom>
      </div>
    </div>
  );
}
