import { useState } from "react";
import img1 from "./1.jpg";
import img2 from "./2.jpg";
import img3 from "./3.jpg";
import img4 from "./4.jpg";
import "./style.css";
export default function Magazine() {
  const arr = [img1, img2, img3, img4];
  const [num, setNum] = useState(0);
  return (
    <div className="magazine">
      <h4>Наш шоурум</h4>

      <article>
        <div
          className="leftSide"
          onClick={() => {
            num != 0 && setNum(num - 1);
            num == 0 && setNum(arr.length - 1);
          }}
        >
          ←
        </div>
        <img src={arr[num]} alt="" />
        <div
          className="rightSide"
          onClick={() => {
            num < arr.length - 1 && setNum(num + 1);
            console.log(num);
            num == arr.length - 1 && setNum(0);
          }}
        >
          →
        </div>
      </article>
    </div>
  );
}
