import { useEffect, useRef, useState } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import Slide from "react-reveal/Slide";
export default function Header({
  setChoosenCategory,
  setChoosenBrand,
  countSumm,
  setOpenCorz,
  global,
  setGlobal,
}) {
  const Navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [shoes, setShoes] = useState();
  const [brand, setBrandParam] = useState([]);
  const [openCategory, isopenCategory] = useState(false);
  const [openBrand, isOpenBrand] = useState(false);
  let listener = null;
  const [searchInput, setSearchInput] = useState("");
  useEffect(() => {
    if (searchInput != "") {
      let form = new FormData();
      form.append("text", searchInput);
      fetch("https://comeback-team.ru/search.php", {
        method: "post",
        body: form,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data != "error") {
            setGlobal(data);
          } else {
            setGlobal([]);
          }
        });
    } else {
      setGlobal([]);
    }
  }, [searchInput]);
  const [scrollState, setScrollState] = useState("top");
  const [mobileMenu, setMobileMenu] = useState(false);
  const [openMe, setOpenMe] = useState();
  useEffect(() => {
    listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled > 0) {
        if (scrollState !== "amir") setScrollState("amir");
      } else {
        if (scrollState !== "top") setScrollState("top");
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, [scrollState]);
  const getBrand = () => {
    fetch("https://comeback-team.ru/cardsApi/getBrand.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setBrandParam(data);
      });
  };
  const copyInputHolder = useRef(null);
  const getCategory = () => {
    fetch("https://comeback-team.ru/cardsApi/getCategory.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCategory(data);
      });
  };
  const getShoes = () => {
    fetch("https://comeback-team.ru/cardsApi/getTitlesShoes.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setShoes(data);
      });
  };
  useEffect(() => {
    getCategory();
    getBrand();
    getShoes();
  }, []);
  const copiedBox = useRef(null);
  const copyText = () => {
    copyInputHolder.current.select();

    document.execCommand("copy");
    copiedBox.current.style.top = "3vh";
    setTimeout(() => {
      copiedBox.current.style.top = "-15vh";
    }, 2000);
  };
  return (
    <div
      className="header"
      style={
        scrollState == "amir" && document.body.clientWidth > 640
          ? { background: "#000" }
          : (window.location.pathname == routes.oplata ||
              window.location.pathname == routes.dostavka ||
              window.location.pathname == routes.order) &&
            document.body.clientWidth > 640
          ? { background: "#000" }
          : {}
      }
    >
      <div className="copyBox" ref={copiedBox}>
        Номер скопирован
      </div>
      {document.body.clientWidth > 640 && (
        <div className="headerBody">
          <div className="logo_Text" onClick={() => Navigate("/")}>
            RARE
          </div>
          <ul className="navItems">
            <li
              onClick={() => {
                Navigate(routes.new);
              }}
            >
              <p>Новинки</p>
            </li>
            <li
              className={`${openBrand && "blockTr"} notFlex`}
              onClick={() => isOpenBrand(!openBrand)}
            >
              <div className="pod">
                <p>Бренды ▼</p>
              </div>
              <ul className="podMenu">
                {brand &&
                  brand.length > 0 &&
                  brand.map((em, index) => {
                    if (em.brand && em.brand != "Jordan") {
                      return (
                        <Link to={`brand/${em.brand}`}>
                          <div
                            className="podmenuEm"
                            key={index}
                            onClick={() => {
                              setChoosenBrand(em.brand);
                            }}
                          >
                            {em.brand}
                          </div>
                        </Link>
                      );
                    }
                  })}
              </ul>
            </li>
            {/*  <li
              onClick={() => {
                Navigate(routes.sales);
              }}
            >
              <p>SALE $</p>
            </li> */}
            <li
              className={`${openCategory && "blockTr"} notFlex`}
              onClick={() => isopenCategory(!openCategory)}
            >
              <div className="pod">
                <p>Одежда ▼</p>
              </div>
              <ul className="podMenu">
                <div
                  className="podmenuEm"
                  onClick={() => {
                    setChoosenCategory("Вся одежда");
                    Navigate(routes.categories + `?key=Вся одежда`);
                  }}
                >
                  Вся одежда
                </div>
                {category &&
                  category.length > 0 &&
                  category.map((em, index) => {
                    if (em.Type != "") {
                      return (
                        <div
                          className="podmenuEm"
                          key={index}
                          onClick={() => {
                            setChoosenCategory(em.Type);

                            Navigate(routes.categories + `?key=${em.Type}`);
                          }}
                        >
                          {em.Type.replaceAll('"', "")}
                        </div>
                      );
                    }
                  })}
              </ul>
            </li>
            <li className={`${openCategory && "blockTr"} notFlex`}>
              <div className="pod">
                <p>Обувь ▼</p>
              </div>
              <ul className="podMenu">
                <div
                  className="podmenuEm"
                  onClick={() => {
                    setChoosenCategory("Вся обувь");
                    Navigate(routes.categories + `?key=Вся обувь`);
                  }}
                >
                  Вся обувь
                </div>
                {shoes &&
                  shoes.length > 0 &&
                  shoes.map((em, index) => {
                    if (em.Description != ";") {
                      return (
                        <div
                          className="podmenuEm"
                          key={index}
                          onClick={() => {
                            setChoosenCategory(em.Description);
                            Navigate(
                              routes.categories + `?key=${em.Description}`
                            );
                          }}
                        >
                          {em.Description}
                        </div>
                      );
                    }
                  })}
              </ul>
            </li>
            <li
              onClick={() => {
                setChoosenCategory("Сумка");

                Navigate(routes.categories + `?key=Сумки`);
              }}
            >
              <p>Сумки</p>
            </li>
          </ul>
          <div style={{ display: "flex", marginLeft: "4vw" }}>
            <input
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="ПОИСК"
            />
            <button
              onClick={() => {
                setGlobal([]);
                setSearchInput("");
              }}
            >
              ✕
            </button>
          </div>
          <a
            className="cont"
            href="tel:+7(985)8127862"
            onClick={(e) => {
              if (document.body.clientWidth > 640) {
                e.preventDefault();
                copyText();
              }
            }}
          >
            <input
              type="text"
              name=""
              ref={copyInputHolder}
              readOnly
              value={"+7(985)8127862"}
              style={{ position: "fixed", top: "-20vw" }}
            />
            <span>+7 (985) 812 7862</span>
          </a>
        </div>
      )}
      {document.body.clientWidth < 640 && (
        <div className="mobile_menu">
          <p className="logo_mobil" onClick={() => Navigate("/")}>
            RARE
          </p>
          <div
            className="mobileSumm"
            onClick={() => {
              setOpenCorz(true);
            }}
          >
            <svg
              class="t-menuwidgeticons__img_carticon"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.67986 6.91023C3.72631 6.39482 4.15832 6 4.67582 6H17.3242C17.8417 6 18.2737 6.39482 18.3201 6.91023L19.4018 18.9102C19.4545 19.4956 18.9935 20 18.4058 20H3.59419C3.00646 20 2.54547 19.4956 2.59823 18.9102L3.67986 6.91023Z"
                stroke="#000"
                stroke-width="1.8"
              ></path>
              <path
                d="M15 9V5C15 2.79086 13.2091 1 11 1C8.79086 1 7 2.79086 7 5V9"
                stroke="#000"
                stroke-width="1.85"
              ></path>
            </svg>
            <div className="cr">{countSumm}</div>
          </div>

          <div
            className="open_menu"
            onClick={() => {
              setMobileMenu(!mobileMenu);
              setGlobal([]);
              setSearchInput("");
            }}
          >
            <div className="menu_line"></div>
            <div className="menu_line"></div>
            <div className="menu_line"></div>
          </div>
          <div
            className="blackMenu"
            id="cl"
            onClick={(e) => {
              e.target.id == "cl" && setMobileMenu(false);
            }}
            style={!mobileMenu ? { display: "none" } : {}}
          >
            {mobileMenu && (
              <Slide left>
                <div className="mobileEms">
                  <div
                    style={{ display: "flex", marginLeft: "4vw" }}
                    className="keks"
                  >
                    <input
                      type="text"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      placeholder="ПОИСК"
                    />
                    <div
                      onClick={() => {
                        setGlobal([]);
                        setSearchInput("");
                      }}
                    >
                      ✕
                    </div>
                  </div>
                  <a className="geg" href="tel:84382425892">
                    8 438 2425892
                  </a>
                  <ul className="navItems">
                    <li
                      onClick={() => {
                        setMobileMenu(false);
                        Navigate(routes.new);
                      }}
                    >
                      <p>Новинки</p>
                    </li>
                    <li
                      className={`${openBrand && "blockTr"} notFlex`}
                      onClick={() => isOpenBrand(!openBrand)}
                    >
                      <div
                        className="pod"
                        onClick={() =>
                          openMe == 1 ? setOpenMe(12) : setOpenMe(1)
                        }
                      >
                        <p>Бренды ▼</p>
                      </div>
                      {openMe == 1 && (
                        <ul className="podMenu">
                          {brand &&
                            brand.length > 0 &&
                            brand.map((em, index) => {
                              if (em.brand) {
                                return (
                                  <Link
                                    className="podmenuEm"
                                    key={index}
                                    to={`brand/${em.brand}`}
                                    onClick={() => {
                                      setChoosenBrand(em.brand);
                                      setMobileMenu(false);
                                    }}
                                  >
                                    {em.brand}
                                  </Link>
                                );
                              }
                            })}
                        </ul>
                      )}
                    </li>
                    {/*  <li
                      onClick={() => {
                        setMobileMenu(false);
                        Navigate(routes.sales);
                      }}
                    >
                      <p>SALE $</p>
                    </li> */}
                    <li
                      className={`${openCategory && "blockTr"} notFlex`}
                      onClick={() => isopenCategory(!openCategory)}
                    >
                      <div
                        className="pod"
                        onClick={() =>
                          openMe == 2 ? setOpenMe(12) : setOpenMe(2)
                        }
                      >
                        <p>Одежда ▼</p>
                      </div>
                      {openMe == 2 && (
                        <ul className="podMenu">
                          <div
                            className="podmenuEm"
                            onClick={() => {
                              setMobileMenu(false);
                              setChoosenCategory("Вся одежда");
                              Navigate(routes.categories + `?key=Вся одежда`);
                            }}
                          >
                            Вся одежда
                          </div>
                          {category &&
                            category.length > 0 &&
                            category.map((em, index) => {
                              if (em.Type != ";") {
                                return (
                                  <div
                                    className="podmenuEm"
                                    key={index}
                                    onClick={() => {
                                      setMobileMenu(false);
                                      setChoosenCategory(em.Type);
                                      Navigate(
                                        routes.categories + `?key=${em.Type}`
                                      );
                                    }}
                                  >
                                    {em.Type.replaceAll('"', "")}
                                  </div>
                                );
                              }
                            })}
                        </ul>
                      )}
                    </li>
                    <li className={`${openCategory && "blockTr"} notFlex`}>
                      <div
                        className="pod"
                        onClick={() =>
                          openMe == 3 ? setOpenMe(12) : setOpenMe(3)
                        }
                      >
                        <p>Обувь ▼</p>
                      </div>
                      {openMe == 3 && (
                        <ul className="podMenu">
                          <div
                            className="podmenuEm"
                            onClick={() => {
                              setMobileMenu(false);
                              setChoosenCategory("Вся обувь");
                              Navigate(routes.categories + `?key=Вся обувь`);
                            }}
                          >
                            Вся обувь
                          </div>
                          {shoes &&
                            shoes.length > 0 &&
                            shoes.map((em, index) => {
                              if (em.Description != ";") {
                                return (
                                  <div
                                    className="podmenuEm"
                                    key={index}
                                    onClick={() => {
                                      setMobileMenu(false);
                                      setChoosenCategory(em.Description);
                                      Navigate(
                                        routes.categories +
                                          `?key=${em.Description}`
                                      );
                                    }}
                                  >
                                    {em.Description}
                                  </div>
                                );
                              }
                            })}
                        </ul>
                      )}
                    </li>
                    <li
                      onClick={() => {
                        setChoosenCategory("Сумка");
                        setMobileMenu(false);
                        Navigate(routes.categories + `?key=Сумки`);
                      }}
                    >
                      <p>Сумки</p>
                    </li>
                  </ul>
                </div>
              </Slide>
            )}{" "}
          </div>
        </div>
      )}
    </div>
  );
}
