import { useEffect, useRef, useState } from "react";
import close from "./close.png";
import "./style.css";
import Summ from "../Summ";
import { Context } from "../../Context";
import { useContext } from "react";
import Marquee from "react-fast-marquee";
import CcontactsComp from "../ContactsComp";
import Tg from "../Tg";
import { useNavigate, useParams } from "react-router-dom";
import { Promo } from "../../Promo";
export default function CardBody({ closeCard, setCorzine }) {
  const [sliderNum, setSliderNum] = useState(0);
  const [em, setEm] = useState(JSON.parse(localStorage.tovar));

  const Navigate = useNavigate();
  // function getCards() {
  //   let formData = new FormData();
  //   formData.append("ID", iditem);
  //   fetch("https://comeback-team.ru/cardsApi/cardParam.php", {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setEm(data[0]);
  //     });
  // }
  const [prom, setProm] = useContext(Promo);
  const [corz, openCorz] = useContext(Context);
  const [sizes, setSizes] = useState();
  const [summ, setSumm] = useState(false);
  const [currentSize, setCurrentSize] = useState("");
  const [bigSize, setBigSize] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [cardPhotos, setCardsPhotos] = useState();
  const [cardsNext, setCardsNext] = useState();

  function getNext() {
    let formData = new FormData();
    formData.append("cat", em.brand);
    fetch("https://comeback-team.ru/cardsApi/getLimit.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCardsNext(data);
      });
  }
  function deleteEm(id) {}

  function getSizes(title) {
    let formData = new FormData();
    formData.append("cat", title);
    fetch("https://comeback-team.ru/cardsApi/getSizes.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data && data[0] && data[0].length > 0) {
          let s = data[0]
            .substr(1, data[0].length - 2)
            .trim()
            .split(",");

          setSizes(s);
          setCurrentSize(s[0]);
          localStorage.size = s[0];
        }
      });
  }
  const txt = useRef(null);
  function copyText() {
    txt.current.select();
    let successful = document.execCommand("copy");
  }
  useEffect(() => {
    if (em) {
      getSizes(em.Title);
      setPhotos(em.Photo.trim().split(" "));
      setCardsPhotos(em.Photo.split(" ")[0]);
      document.getElementsByClassName("popup")[0].scrollTo(0, 0);
      getNext();
    }
  }, [em]);
  useEffect(() => {
    if (em) {
      setCardsPhotos(em.Photo.trim().split(" ")[sliderNum]);
    }
  }, [sliderNum]);
  return (
    <div
      className="popup"
      style={
        bigSize && document.body.clientWidth > 640
          ? { paddingTop: "0" }
          : bigSize && document.body.clientWidth < 640
          ? { overflow: "hidden" }
          : {}
      }
    >
      {em && em.brand && (
        <div>
          {summ && <Summ />}
          <img
            src={close}
            className="close"
            alt=""
            onClick={() => {
              bigSize && document.body.clientWidth > 640
                ? setBigSize(false)
                : closeCard(false);
            }}
          />

          <div
            className="backPop"
            onClick={() => {
              bigSize ? setBigSize(false) : closeCard(false);
            }}
          >
            {bigSize ? " ← Назад к товару" : " ← Вернуться в магазин"}
          </div>
          {document.body.clientWidth < 640 && bigSize && (
            <div
              className="cardSlider moibileSize"
              style={
                bigSize && document.body.clientWidth > 640
                  ? {
                      height: "100vh",
                      width: "70vw",
                    }
                  : {}
              }
            >
              <div
                className="sSlider"
                style={
                  bigSize && document.body.clientWidth > 640
                    ? {
                        height: "100%",
                        width: "fit-content",
                        margin: "0 auto",
                      }
                    : {}
                }
              >
                <div
                  className="sSliderBody"
                  style={
                    bigSize && document.body.clientWidth > 640
                      ? {
                          height: "100%",
                        }
                      : {}
                  }
                >
                  <img
                    src={cardPhotos && cardPhotos.replace('"', "")}
                    alt=""
                    onClick={() => setBigSize(true)}
                    className={
                      bigSize && document.body.clientWidth > 640 && "bigSize"
                    }
                    style={
                      document.body.clientWidth > 640 && em.Type == "Обувь"
                        ? { height: "70%" }
                        : {}
                    }
                  />
                </div>
              </div>

              <div className="quadrFlex">
                {photos &&
                  photos.length > 0 &&
                  photos.map((elem, index) => {
                    if (elem.match(/\.(jpeg|jpg|gif|png|webp)$/)) {
                      console.log(elem.replace('"', ""));
                      return (
                        <img
                          // style={{
                          //   backgroundImage:
                          //     "url(" + elem.replace('"', "") + ")",
                          // }}
                          src={elem.replace('"', "")}
                          className="quadrImg"
                          key={index}
                          onClick={() => {
                            setCardsPhotos(photos[index]);
                            setSliderNum(index);
                          }}
                        ></img>
                      );
                    }
                  })}
              </div>
            </div>
          )}
          <div className="popbody">
            <div
              className="cardSlider"
              style={
                bigSize && document.body.clientWidth > 640
                  ? {
                      height: "100vh",
                      width: "70vw",
                    }
                  : {}
              }
            >
              <div
                className="left"
                onClick={() => {
                  sliderNum != 0 && setSliderNum(sliderNum - 1);
                }}
                style={sliderNum == 0 ? { opacity: "0.4" } : {}}
              >
                &#5171;
              </div>

              <div
                className="sSlider"
                style={
                  bigSize && document.body.clientWidth > 640
                    ? {
                        height: "100%",
                        width: "fit-content",
                        margin: "0 auto",
                      }
                    : {}
                }
              >
                <div
                  className="sSliderBody"
                  style={
                    bigSize && document.body.clientWidth > 640
                      ? {
                          height: "100%",
                        }
                      : {}
                  }
                >
                  <img
                    src={cardPhotos && cardPhotos.replace('"', "")}
                    alt=""
                    onClick={() => setBigSize(true)}
                    className={
                      bigSize && document.body.clientWidth > 640 && "bigSize"
                    }
                    style={
                      document.body.clientWidth > 640 && em.Type == "Обувь"
                        ? { height: "70%" }
                        : {}
                    }
                  />
                </div>
              </div>
              <div
                className="right"
                onClick={() => {
                  sliderNum != photos.length - 1 &&
                    photos[sliderNum + 1].match(/\.(jpeg|jpg|gif|png|webp)$/) &&
                    setSliderNum(sliderNum + 1);
                }}
                style={sliderNum == photos.length - 1 ? { opacity: "0.4" } : {}}
              >
                &#5171;
              </div>
              <div className="quadrFlex">
                {photos &&
                  photos.length > 0 &&
                  photos.map((elem, index) => {
                    if (elem.match(/\.(jpeg|jpg|gif|png|webp)$/)) {
                      console.log(elem.replace('"', ""));
                      return (
                        <img
                          // style={{
                          //   backgroundImage:
                          //     "url(" + elem.replace('"', "") + ")",
                          // }}
                          src={elem.replace('"', "")}
                          className="quadrImg"
                          key={index}
                          onClick={() => {
                            setCardsPhotos(photos[index]);
                            setSliderNum(index);
                          }}
                        ></img>
                      );
                    }
                  })}
              </div>
            </div>
            <card
              className="infos"
              style={
                bigSize && document.body.clientWidth > 640
                  ? { display: "none" }
                  : {}
              }
            >
              <h4>{em.Title}</h4>
              <p style={{ color: "gray" }}>{em.brand}</p>

              <div className="priceCard">
                <p>
                  {" "}
                  {prom
                    ? Math.round(em.Price - (em.Price / 100) * 10) + "р."
                    : em.Price + "р."}
                  <span
                    style={{
                      opacity: "0.7",
                      fontWeight: "400",
                      textDecoration: "line-through",
                    }}
                  >
                    {" "}
                    {em.PriceOld.length > 0 && em.PriceOld + "р."}
                  </span>
                </p>
                {!localStorage.idTelegram && (
                  <span
                    className="linkTg"
                    href="https://t.me/rare_salingStore_bot"
                    target="_blank"
                  >
                    (<b>{(em.Price * 0.95).toFixed()}</b>р. по промокоду RARE5)
                  </span>
                )}
              </div>
              <p style={{ color: "gray" }}>Размер:</p>
              <div className="sizeBody">
                {sizes &&
                  sizes.length > 0 &&
                  sizes.map((em, index) => {
                    if (index == 0 && em != ";") {
                      return (
                        <div style={{ display: "flex" }} className="sizeGet">
                          <input
                            type="radio"
                            defaultChecked
                            value={em}
                            name="size"
                            onClick={() => {
                              setCurrentSize(em);

                              localStorage.size = em;
                            }}
                          />

                          <p>{em}</p>
                        </div>
                      );
                    } else {
                      return (
                        <div style={{ display: "flex" }} className="sizeGet">
                          <input
                            type="radio"
                            value={em}
                            name="size"
                            onClick={() => {
                              setCurrentSize(em);

                              localStorage.size = em;
                            }}
                          />
                          <p>{em}</p>
                        </div>
                      );
                    }
                  })}
              </div>

              <div className="sizes">
                <div className="cur_v"></div>
              </div>
              <div style={{ display: "flex" }} className="mobFlex">
                <div
                  className="gos"
                  onClick={() => {
                    let item = JSON.parse(localStorage.tovar);
                    item.Quantity = currentSize;
                    // if (localStorage.sizesArr) {
                    //   localStorage.sizesArr = JSON.stringify(
                    //     JSON.parse(localStorage.sizesArr).push(1)
                    //   );
                    // }
                    setCorzine((corzine) => [...corzine, item]);
                    openCorz(true);
                  }}
                >
                  Оформить заказ
                </div>
                <div
                  className="corzi"
                  onClick={() => {
                    let item = JSON.parse(localStorage.tovar);
                    item.Quantity = currentSize;

                    setCorzine((corzine) => [...corzine, item]);
                    closeCard(false);
                  }}
                >
                  Добавить в корзину
                </div>
              </div>
              <div
                className="info"
                dangerouslySetInnerHTML={
                  em.Texts != ";" && em.Texts != ""
                    ? { __html: em.Texts }
                    : { __html: "" }
                }
              ></div>
              <div className="approach">
                <input
                  ref={txt}
                  type="text"
                  readOnly
                  value={"https://rarestore.ru?item=" + em.ID}
                />
                <div onClick={() => copyText()}>Поделиться товаром</div>
              </div>
            </card>
          </div>

          <CcontactsComp></CcontactsComp>
          <div className="otherEms">
            <div className="otherEmsTrain">
              <Marquee speed={20} pauseOnClick={true}>
                {cardsNext &&
                  cardsNext.length > 0 &&
                  cardsNext.map((em, index) => {
                    let cardImg = em.Photo.split(" ");

                    let photo = em.Description;
                    return (
                      <div
                        className="card"
                        key={index}
                        onClick={() => {
                          localStorage.setItem("tovar", JSON.stringify(em));
                          setEm(em);
                        }}
                      >
                        <div className="hei">
                          <img
                            style={
                              photo == "Кроссовки" ||
                              photo == '"Тактические ботинки"' ||
                              photo == "Полуботинки" ||
                              photo == "Ботинки"
                                ? { width: "100%", height: "auto" }
                                : {}
                            }
                            src={
                              cardImg && cardImg.length > 0
                                ? cardImg[0].replace('"', "")
                                : ""
                            }
                            alt=""
                          />
                        </div>
                        <div className="cardInfo">
                          <p>{em.Title}</p>
                          <span>{em.Description}</span>
                          <div className="price">
                            {em.Price} р.{" "}
                            <span>
                              {Number(em.PriceOld) != false &&
                                em.PriceOld + " р."}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Marquee>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
