import { useContext, useEffect, useState } from "react";
import "./style.css";
import close from "./close.png";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { Promo } from "../../Promo";
export default function Summ({ setCorzine, setOpenCorz, items }) {
  const [em, setEm] = useState(JSON.parse(localStorage.tovar));
  const Navigate = useNavigate();
  const [num, setNum] = useState(1);
  const [prom, setProm] = useContext(Promo);
  const [name, setName] = useState("");
  const [promik, setPromik] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [city, setCity] = useState("");
  const [adress, setAdress] = useState("");
  const [comm, setCom] = useState("");
  const [road, setRoad] = useState("Самовывоз с магазина");
  const [oplata, setOplata] = useState("Наличными при получении");
  const [getSumm, setGetSumm] = useState(0);
  let arr = [];
  for (let i = 0; i < items.length; i++) {
    arr.push(1);
  }
  const [arrNums, setArrNums] = useState(arr);

  useEffect(() => {
    let sum = 0;
    for (let i = 0; i < items.length; i++) {
      sum += Number(items[i].Price) * arrNums[i];
      // if (Number(items[i].Price)) {
      //   setGetSumm(Number(getSumm) + Number(items[i].Price));
      // }
    }
    setGetSumm(sum);
  }, [arrNums, items]);
  function deleteEm(id) {
    setCorzine(items.filter((item, index) => index !== id));
  }

  useEffect(() => {
    localStorage.sizesArr = JSON.stringify(arrNums);
  }, [arrNums]);
  useEffect(() => {
    if (items.length == 0) {
      setOpenCorz(false);
    }
  }, [items]);
  const sendForm = () => {
    let string = [];
    for (let i = 0; i < items.length; i++) {
      string.push(
        "<b>" +
          (i + 1) +
          "</b> " +
          "." +
          items[i].Title +
          ": " +
          Number(items[i].Price * arrNums[i]) +
          " (" +
          items[i].Price +
          " x " +
          arrNums[i] +
          ") " +
          "Размер: " +
          items[i].Quantity
      );
    }

    let txt = `<b>${road}</b>
    <b>Сумма платежа: ${
      prom
        ? Math.round(getSumm - (getSumm / 100) * 5) +
          (road == "Курьерская по Москве 500 руб." ? 500 : 0)
        : promik == "RARE5"
        ? (getSumm / 100) * 95 +
          (road == "Курьерская по Москве 500 руб." ? 500 : 0)
        : getSumm + (road == "Курьерская по Москве 500 руб." ? 500 : 0)
    } RUB</b>
    <b>Платежная система: ${oplata}</b>
    <b>Информация о покупателе:</b>
    <b>Имя: ${name}</b>
    <b>Email: ${email}</b>
    <b>Телефон: ${tel}</b>
    <b>Город: ${city}</b>
    <b>Адрес: ${adress}</b>
    <b>Комментарий: ${comm}</b>`;
    let formData = new FormData();
    formData.append("arrT", JSON.stringify(string));
    formData.append(
      "txt",
      JSON.stringify([
        road,
        `<b>Сумма платежа:</b> ${
          prom
            ? Math.round(getSumm - (getSumm / 100) * 5) +
              (road == "Курьерская по Москве 500 руб." ? 500 : 0)
            : promik == "RARE5"
            ? (getSumm / 100) * 95 +
              (road == "Курьерская по Москве 500 руб." ? 500 : 0)
            : getSumm + (road == "Курьерская по Москве 500 руб." ? 500 : 0)
        } RUB`,
        `<b>Платежная система:</b> ${oplata}`,
        `<b>Имя:</b> ${name}`,
        `<b>Email:</b> ${email}`,
        `<b>Телефон:</b> ${tel}`,
        `<b>Город:</b> ${city}`,
        `<b>Адрес:</b> ${adress}`,
        `<b>Комментарий:</b> ${comm}`,
      ])
    );
    if (oplata == "Оплата картой" && localStorage.idTelegram) {
      formData.append("idUser", localStorage.idTelegram);
    }
    fetch("https://comeback-team.ru/cardsApi/bot.php", {
      method: "post",
      body: formData,
    }).then((response) => {
      if (response.status == 200) {
        setOpenCorz(false);
        localStorage.corz = "";
        setCorzine([]);
        localStorage.sizesArr = "";
        if (localStorage.idTelegram) {
          let dt = new FormData();

          dt.append("cat", localStorage.idTelegram);
          fetch("https://comeback-team.ru/firstBuy.php", {
            method: "post",
            body: dt,
          });
          let lastData = new FormData();
          lastData.append("idUser", localStorage.idTelegram);
          lastData.append(
            "details",
            JSON.stringify([
              road,
              `Сумма платежа: ${
                prom
                  ? Math.round(getSumm - (getSumm / 100) * 5) +
                    (road == "Курьерская по Москве 500 руб." ? 500 : 0)
                  : promik == "RARE5"
                  ? (getSumm / 100) * 95 +
                    (road == "Курьерская по Москве 500 руб." ? 500 : 0)
                  : getSumm +
                    (road == "Курьерская по Москве 500 руб." ? 500 : 0)
              } RUB`,
              `Платежная система: ${oplata}`,
              `Имя: ${name}`,
              `Email: ${email}`,
              `Телефон: ${tel}`,
              `Город: ${city}`,
              `Адрес: ${adress}`,
              `Комментарий: ${comm}`,
            ])
          );
          lastData.append("idComp", 23);
          lastData.append("tel", tel);
          lastData.append(
            "sum",
            prom ? Math.round(getSumm - (getSumm / 100) * 5) : getSumm
          );
          lastData.append("status", "Создан");
          let date = new Date();
          lastData.append("date", date.toString());
          fetch("https://comeback-team.ru/sellOrder.php", {
            method: "post",
            body: lastData,
          });
          setProm(false);
        } else {
          let lastData = new FormData();
          lastData.append(
            "details",
            JSON.stringify([
              road,
              `Сумма платежа: ${
                prom
                  ? Math.round(getSumm - (getSumm / 100) * 5) +
                    (road == "Курьерская по Москве 500 руб." ? 500 : 0)
                  : promik == "RARE5"
                  ? (getSumm / 100) * 95 +
                    (road == "Курьерская по Москве 500 руб." ? 500 : 0)
                  : getSumm +
                    (road == "Курьерская по Москве 500 руб." ? 500 : 0)
              } RUB`,
              `Платежная система: ${oplata}`,
              `Имя: ${name}`,
              `Email: ${email}`,
              `Телефон: ${tel}`,
              `Город: ${city}`,
              `Адрес: ${adress}`,
              `Комментарий: ${comm}`,
            ])
          );
          lastData.append("tel", tel);
          lastData.append("idComp", 23);
          lastData.append(
            "sum",
            prom ? Math.round(getSumm - (getSumm / 100) * 5) : getSumm
          );
          lastData.append("status", "Создан");
          let date = new Date();
          lastData.append("date", date);
          fetch("https://comeback-team.ru/sellOrder.php", {
            method: "post",
            body: lastData,
          })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              Navigate(routes.order + "?id=" + data[0].ID);
            });
        }

        setProm(false);
      }
    });
  };
  let photo = em.Photo.split(" ")[0];
  return (
    <div className="corz">
      <img
        src={close}
        className="closee"
        alt=""
        onClick={() => setOpenCorz(false)}
      />
      <div className="corz_body1">
        {document.body.clientWidth < 640 && (
          <div
            style={{ position: "static" }}
            className="backPop"
            onClick={() => {
              setOpenCorz(false);
            }}
          >
            ← Вернуться в магазин
          </div>
        )}
        <div className="flexi">
          <h3 className="hC">Ваша корзина:</h3>
          <div className="listCorz">
            {items &&
              items.length > 0 &&
              items.map((em, index) => {
                return (
                  <div className="corz_em" key={index}>
                    <div className="corz_info">
                      <img
                        src={em.Photo.replace('"', "").split(" ")[0]}
                        alt=""
                      />
                      <div className="corz_body">
                        <p className="corz_body_title">{em.Title}</p>
                        <span>{"Размер: " + em.Quantity}</span>
                        <div className="corz_body_sumprice">
                          <p className="corz_body_sum">
                            {" "}
                            {prom
                              ? Math.round(em.Price - (em.Price / 100) * 10) +
                                " "
                              : em.Price + " "}
                            руб.
                          </p>
                          <div className="corz_bodyButtons">
                            <div
                              className="minus"
                              onClick={() => {
                                arrNums[index] != 1
                                  ? setArrNums((existingItems) => {
                                      return [
                                        ...existingItems.slice(0, index),
                                        existingItems[index] - 1,
                                        ...existingItems.slice(index - 1),
                                      ];
                                    })
                                  : deleteEm(index);
                              }}
                            >
                              -
                            </div>
                            <div className="numCorz">{arrNums[index]}</div>
                            <div
                              className="plus"
                              onClick={() => {
                                setArrNums((existingItems) => {
                                  return [
                                    ...existingItems.slice(0, index),
                                    existingItems[index] + 1,
                                    ...existingItems.slice(index + 1),
                                  ];
                                });
                              }}
                            >
                              +
                            </div>
                            <p
                              className="deleteCorz"
                              onClick={() => deleteEm(index)}
                            >
                              Удалить
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="flexi">
          <h3 className="hC">Ваши данные:</h3>
          <div className="input_text">
            <p>Имя</p>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="input_text">
            <p>Email</p>
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="input_text">
            <p>Телефон</p>
            <input
              type="tel"
              value={tel}
              onChange={(e) => {
                setTel(e.target.value);
              }}
            />
          </div>
          {/*    <div className="input_text">
            <p>Город</p>
            <input
              type="text"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
          </div> */}
          <div className="input_text">
            <p>Адрес</p>
            <input
              type="adress"
              value={adress}
              onChange={(e) => {
                setAdress(e.target.value);
              }}
            />
          </div>
          <div className="input_text">
            <p>Промокод</p>
            <input
              type="text"
              value={promik}
              onChange={(e) => {
                setPromik(e.target.value);
              }}
            />
          </div>
          <div className="radioCh">
            <div onClick={() => setRoad("Самовывоз с магазина")}>
              <input
                type="radio"
                name="getter"
                value="Самовывоз с магазина"
                defaultChecked
                id="ggg"
              />
              <label htmlFor="ggg">Самовывоз с магазина</label>
            </div>
            <div onClick={() => setRoad("Курьерская по Москве 500 руб.")}>
              <input
                type="radio"
                name="getter"
                value="Курьерская по Москве 500 руб. (Мкад)"
                id="kkk"
              />
              <label htmlFor="kkk">Курьерская по Москве 500 руб.</label>
            </div>
            <div onClick={() => setRoad("Доставка почтой")}>
              <input
                type="radio"
                name="getter"
                value="Доставка почтой"
                id="sss"
              />
              <label htmlFor="sss">Доставка почтой/CDEK</label>
            </div>
          </div>
          <Link to={routes.dostavka} target="_blank">
            Как работает доставка?
          </Link>
          <div className="input_text">
            <p>Комментарий</p>
            <textarea
              name=""
              value={comm}
              onChange={(e) => {
                setCom(e.target.value);
              }}
              id=""
              cols="20"
              rows="4"
            ></textarea>
          </div>
          <div className="radioCh">
            <div onClick={() => setOplata("Наличными при получении")}>
              <input
                type="radio"
                name="pr"
                value="Наличными при получении"
                defaultChecked
                id="bbb"
              />
              <label htmlFor="bbb">Наличными при получении</label>
            </div>
            <div onClick={() => setOplata("Оплата картой")}>
              <input type="radio" name="pr" value="Оплата картой" id="ooo" />
              <label htmlFor="ooo">Оплата картой</label>
            </div>
          </div>

          <div className="summPr">
            Итоговая сумма:{" "}
            {prom
              ? Math.round(getSumm - (getSumm / 100) * 5).toFixed(0) +
                (road == "Курьерская по Москве 500 руб." ? 500 : 0)
              : promik == "RARE5"
              ? (getSumm / 100).toFixed(0) * 95 +
                (road == "Курьерская по Москве 500 руб." ? 500 : 0)
              : getSumm +
                (road == "Курьерская по Москве 500 руб." ? 500 : 0)}{" "}
            руб.{" "}
            {prom && (
              <span style={{ textDecoration: "line-through" }}>
                {getSumm} руб.
              </span>
            )}
          </div>
          <div
            className="corzAdd"
            id="formSend"
            onClick={() => {
              tel.length > 0 ? sendForm() : alert("Укажите хотя бы телефон");
            }}
          >
            Оформить заказ
          </div>
        </div>
      </div>
    </div>
  );
}
